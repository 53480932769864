
import React,{Component} from 'react';
import URL from '../URL'
import { withRouter } from 'react-router-dom';
import * as NumericInput from "react-numeric-input";
import Cookies from 'universal-cookie';
const cookies = new Cookies();


class Step extends Component{
  constructor(props) {

    super(props);
   
      this.state = {
        LoanList:[]
      }
    

      this.handleNext = this.handleNext.bind(this);


    }




    async  componentDidMount() {

      await  this.fetchLoanList()
   
       
     }
   
     fetchLoanList() {
       fetch(URL + "/APP-API/Product/GetLoanList", {
         method: 'post',
         header: {
           'Accept': 'application/json',
           'Content-type': 'application/json'
         },
         body: JSON.stringify({
   
   
         })
   
       })
         .then((response) => response.json())
         .then((responseJson) => {
   
   
            console.log('LoanList',responseJson)
   
           this.setState({ LoanList: responseJson.data, inMemoryrestorentData: responseJson.data });
   
   
   
   
   
         })
         .catch((error) => {
           //  console.error(error);
   
         });
   
   
     }
   
   

    render() { 



    
    return (
    
      <React.Fragment>
  <div class="bg-white section-space80">
        <div class="container">
            <div class="row">
                <div class="offset-xl-2 col-xl-8 offset-md-2 col-md-8 offset-md-2 col-md-8 col-sm-12 col-12">
                    <div class="mb100 text-center section-title">
                        
                        <h1>Fast &amp; Easy Application Process.</h1>
                        <p>Suspendisse aliquet varius nunc atcibus lacus sit amet coed portaeri sque mami luctus viveed congue lobortis faucibus.</p>
                    </div>
                    
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="bg-white pinside40 number-block outline mb60 bg-boxshadow">
                        <div class="circle"><span class="number">1</span></div>
                        <h3 class="number-title mb-1">Choose Loan Amount</h3>
                        <p>Suspendisse accumsan imperdue ligula dignissim sit amet vestibulum in mollis etfelis.</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="bg-white pinside40 number-block outline mb60 bg-boxshadow">
                        <div class="circle"><span class="number">2</span></div>
                        <h3 class="number-title mb-1">Approved Your Loan</h3>
                        <p>Fusce tempor sstibulum varius sem nec mi luctus viverra edcongue lobortis faucibus.</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="bg-white pinside40 number-block outline mb60 bg-boxshadow">
                        <div class="circle"><span class="number">3</span></div>
                        <h3 class="number-title mb-1">Get Your Cash</h3>
                        <p>Get your money in minutes simtibulm varius semnec mluctus gue lobortis faucibus.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="offset-xl-2 col-xl-8 offset-md-2 col-md-8 offset-md-2 col-md-8 col-sm-12 col-12 text-center"> <a href="#!" class="btn btn-secondary">View Our Loans</a> </div>
            </div>
        </div>
    </div>


    <div class="section-space80">
        <div class="container">
            <div class="row">
                <div class="offset-xl-2 col-xl-8 offset-md-2 col-md-8 offset-md-2 col-md-8 col-sm-12 col-12">
                    <div class="mb60 text-center section-title">
                      
                        <h1>Why People Choose Us</h1>
                        <p>Suspendisse aliquet varius nunc atcibus lacus sit amet coed portaeri sque mami luctus viveed congue lobortis faucibus.</p>
                    </div>
                    
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="bg-white bg-boxshadow">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 nopadding">
                                <div class="bg-white pinside60 number-block outline">
                                    <div class="mb20"><i class="icon-command  icon-4x icon-primary"></i></div>
                                    <h3>Dedicated Specialists</h3>
                                    <p>Duis eget diam quis elit erdiet alidvolutp terdum tfanissim non intwesollis eu mauris.</p>
                                    <a href="#!" class="btn btn-outline mt20">Meet the team</a>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 nopadding">
                                <div class="bg-white pinside60 number-block outline">
                                    <div class="mb20"><i class="icon-cup  icon-4x icon-primary"></i></div>
                                    <h3>Success Stories Rating</h3>
                                    <p>Integer facilisis fringilla dolor ut luctus lvinar felis miat velitliquam at fermentum orci.</p>
                                    <a href="#!" class="btn btn-outline mt20">View Client Review</a>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 nopadding">
                                <div class="bg-white pinside60 number-block outline">
                                    <div class="mb20"><i class="icon-calculator  icon-4x icon-primary"></i></div>
                                    <h3>No front Appraisal Fees!</h3>
                                    <p> Integer faisis fringilla dolor ut luctus nisi eneinar felis viverra dignissim fermentum orci.</p>
                                    <a href="#!" class="btn btn-outline mt20">Why choose us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="section-space80 bg-white">
        <div class="container">
            <div class="row">
                <div class="offset-xl-2 col-xl-8 offset-md-2 col-md-8 offset-md-2 col-md-8 col-sm-12 col-12">
                    <div class="mb60 text-center section-title">
                       
                        <h1>We are Here to Help You</h1>
                        <p>Our mission is to deliver reliable, latest news and opinions.</p>
                    </div>
                   
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="bg-white bg-boxshadow pinside40 outline text-center mb30">
                        <div class="mb40"><i class="icon-calendar-3 icon-2x icon-default"></i></div>
                        <h2 class="capital-title">Apply For Loan</h2>
                        <p>Looking to buy a car or home loan? then apply for loan now.</p>
                        <a href="#!" onClick={()=>this.props.history.push("/GetQuote")}  class="btn-link">Get Appointment</a>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="bg-white bg-boxshadow pinside40 outline text-center mb30">
                        <div class="mb40"><i class="icon-phone-call icon-2x icon-default"></i></div>
                        <h2 class="capital-title">Call us at </h2>
                        <h1 class="text-big">800-123-456 / 789 </h1>
                        <p>lnfo@loanadvisor.com</p>
                        <a href="#!" class="btn-link">Contact us</a>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="bg-white bg-boxshadow pinside40 outline text-center mb30">
                        <div class="mb40"> <i class="icon-users icon-2x icon-default"></i></div>
                        <h2 class="capital-title">Talk to Advisor</h2>
                        <p>Need to loan advise? Talk to our Loan advisors.</p>
                        <a href="#!" class="btn-link">Meet The Advisor</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

      </React.Fragment>

        )};

        handleNext(loanId, loanName) {
          const Routation = "/Loan/"+loanId + "/" + loanName;
          this.props.history.push(Routation);
      
        }

  }

export default withRouter(Step);
