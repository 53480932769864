import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Header from '../component/Header';
import Footer from '../component/Footer';
import Detail from '../component/Detail';
import Cookies from 'universal-cookie';

const cookie = new Cookies();

class AddR extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {


    }

    render() {
        return (
            <>

                <Header />

                <Detail />

                <Footer />

            </>
        )
    }
}

export default withRouter(AddR);
