import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Header from '../component/Header';
import Footer from '../component/Footer';
import Eligibily from '../component/EligibilyCalculator';

class LoanCalculator extends Component {

    render() {
        return (
            <React.Fragment>

                <Header />
                <div class="page-header">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="page-breadcrumb">
                                    <ol class="breadcrumb">
                                        <li><a href="index.html">Home</a></li>
                                        <li class="active">Loan Calculator</li>
                                    </ol>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="bg-white pinside30">
                                    <div class="row align-items-center">
                                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                            <h1 class="page-title">Loan Calculator</h1>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                            <div class="btn-action"> <a href="#!" class="btn btn-secondary">How To Apply</a> </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sub-nav" id="sub-nav">
                                    <ul class="nav nav-justified">
                                        <li class="nav-item">
                                            <a href="contact-us.html" class="nav-link">Give me call back</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="#!" class="nav-link">Emi Caculator</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="wrapper-content bg-white p-3 p-lg-5">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="row">
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                                <div class="bg-light bg-white p-3 p-lg-5 outline">
                                                    <span>Loan Amount is </span>
                                                    <strong>
                                                        <span class="" id="la_value">30000</span></strong>
                                                    <input type="text" data-slider="true" value="30000" data-slider-range="100000,5000000" data-slider-step="10000" data-slider-snap="true" id="la" />
                                                    <hr />
                                                    <span>No. of Month is <strong>
                                                        <span class="" id="nm_value">30</span> </strong>
                                                    </span>
                                                    <input type="text" data-slider="true" value="30" data-slider-range="120,360" data-slider-step="1" data-slider-snap="true" id="nm" />
                                                    <hr />
                                                    <span>Rate of Interest [ROI] is <strong><span class="" id="roi_value">10</span>
                                                    </strong>
                                                    </span>
                                                    <input type="text" data-slider="true" value="10.2" data-slider-range="8,16" data-slider-step=".05" data-slider-snap="true" id="roi" />
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                                <div class="row">
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div class="bg-light p-3 outline">
                                                            Monthly EMI
                                                            <h2 id='emi' class="mb-0"></h2>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div class="bg-light p-3 outline">
                                                            Total Interest
                                                            <h2 id='tbl_int' class="mb-0"></h2>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div class="bg-light p-3 outline"> Payable Amount
                                                            <h2 id='tbl_full' class="mb-0"></h2>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div class="bg-light p-3 outline">
                                                            Interest Percentage
                                                            <h2 id='tbl_int_pge' class="mb-0"></h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </React.Fragment>
        );
    }

}

export default withRouter(LoanCalculator);
