import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router';
import Cookies from 'universal-cookie';
import { GoHome } from 'react-icons/go';
import { BiBath } from 'react-icons/bi';
import { BsPeople } from 'react-icons/bs';
import { RiBankLine } from 'react-icons/ri';
import { FcDepartment } from 'react-icons/fc';
import { TiChartAreaOutline } from 'react-icons/ti';
import { ToastContainer, toast } from 'react-toastify';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import formatAmount from 'indian-currency-formatter';
import Loader from "react-loader-spinner";

const cookie = new Cookies();

class Rent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            ListingData: [],
            propertyID: null,
            UserID: null,
            dataFatched: false,
            imagesData: [],
            name: '',
            phone: ''
        }

    }

    componentDidMount = async () => {
        this.setState({ propertyID: await this.props.location.state.id });
        this.GetOurProducts();
        this.GetPropertyImages();
    }

    GetOurProducts = () => {

        fetch(URL + '/APP-API/Partner/GetRealEsateDataByID', {

            method: 'post',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({

                listing_id: this.state.propertyID

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                console.log("navneey", responseJson);

                this.setState({ ListingData: responseJson.data[0], isLoading: false })


            })
            .catch((error) => {
                //  console.error(error);

            });

    }

    GetPropertyImages() {

        fetch(URL + '/APP-API/Partner/GetPropertyImages', {

            method: 'post',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({

                listing_id: this.state.propertyID

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                this.setState({ imagesData: responseJson.data, })


            })
            .catch((error) => {
                //  console.error(error);

            });

    }

    addLeadProperty = (id, p_goal) => {

        fetch(URL + '/APP-API/Partner/addLeadPropertyWeb', {
            method: 'post',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({

                property_id: id,
                p_goal: p_goal,
                name: this.state.name,
                phone: this.state.phone

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status == 'done') {
                    toast.success("We will contact you soon!");
                }
                else {
                    toast.error("Network are bussy! Please try after sometime");
                }

            })
            .catch((error) => {
                //  console.error(error);

            });
    }

    handleNext = (props) => {
        this.props.history.push(props);
    }

    render() {
        const nth = function (d) {
            if (d > 3 && d < 21) return 'th';
            switch (d % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
            }
        };
        const month = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const data = this.state.ListingData;
        if (this.state.isLoading) {
            return (
                <>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "500px", background: "#fff" }}>
                        <Loader type="Rings" color="#f51f8a" height={130} width={130} />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLongTitle">Fill Your Basic Details</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form>
                                        <div class="form-group">
                                            <label for="exampleInputName1">Name</label>
                                            <input type="text" class="form-control" onChange={(e) => this.setState({ name: e.target.value })} id="exampleInputName1" aria-describedby="NameHelp" placeholder="Enter Name" />
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputPhone1">Phone</label>
                                            <input type="tell" class="form-control" onChange={(e) => this.setState({ phone: e.target.value })} id="exampleInputPhone1" placeholder="Phone" />
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button" onClick={() => this.addLeadProperty(this.state.propertyID, this.state.ListingData.p_goal)} class="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="my-4">
                            <div className="row" style={{ borderRadius: 5 }}>
                                <div class="col-xl-4 col-12" style={{ background: "#fff" }}>
                                    {this.state.imagesData.length ? (
                                        <OwlCarousel className='owl-theme my-3'
                                            items={1}
                                            loop
                                            margin={20}
                                            nav={true}
                                            autoplay={true}
                                            dots={false}
                                            navText={["<button style='padding: 11px 19px; background: rgb(0 0 0 / 42%); border-radius: 50%; border: none' class='button_my'><i class='fa fa-angle-left' style='color: #fff' aria-hidden='true'></i></button>",
                                                "<button style='padding: 11px 19px; background: rgb(0 0 0 / 42%); border-radius: 50%; border: none' class='button_my'><i class='fa fa-angle-right' style='color: #fff' aria-hidden='true'></i></button>"]}
                                        >
                                            <div class='item'>
                                                <img className="image_resposive" style={{ margin: "auto", width: "100%", height: "225px", objectFit: "contain" }} src={URL + "/APP-API/Assets/documents/" + data.cover_image} />
                                            </div>
                                            {this.state.imagesData.map((item, i) => {
                                                return (
                                                    <div class='item'>
                                                        <img className="image_resposive" style={{ margin: "auto", width: "100%", height: "225px", objectFit: "contain" }} src={URL + "/APP-API/Assets/documents/" + item.cover_image} />
                                                    </div>
                                                )
                                            })}
                                        </OwlCarousel>
                                    ) : (<div className="my-3"> <img className="image_resposive" style={{ margin: "auto", width: "100%", height: "225px", objectFit: "contain" }} src={URL + "/APP-API/Assets/documents/" + data.cover_image} /></div>)}
                                </div>
                                <div class="col-xl-8 col-12 p-3" style={{ background: "#fff" }}>
                                    <div>
                                        <div className="d-flex align-items-center" style={{ justifyContent: "space-between" }}>
                                            <h2 className="my-2">{data.heading}</h2>
                                            <div className="d-flex align-items-center"><FcDepartment /> <p style={{ fontSize: 12 }}>{data.p_type}</p></div>
                                        </div>
                                        <p>{data.address_1}, {data.pin_code}</p>
                                    </div>
                                    <div className="details my-2 d-flex" style={{ borderTop: "1px solid #d2d2d2", borderBottom: "1px solid #d2d2d2", flexDirection: "row", justifyContent: "space-around" }}>
                                        {data.p_goal == "Sale" ? (
                                            <>
                                                <div className="details_items d-flex my-3" style={{ flexDirection: "row", alignItems: "center" }}>
                                                    <RiBankLine style={{ fontSize: 26 }} /><span style={{ fontSize: 12, fontWeight: "700" }}>(Loan Availability)</span> &nbsp; <span style={{ fontWeight: "bold" }}> &nbsp; : &nbsp;&nbsp; </span> <h6 className="m-0" style={{ fontSize: 16, marginLeft: 5, fontWeight: "400" }}>{data.is_loan_avl}</h6>
                                                </div>
                                                <div className="details_items d-flex my-3" style={{ flexDirection: "row", alignItems: "center" }}>
                                                    <BsPeople style={{ fontSize: 26 }} /><span style={{ fontSize: 12, fontWeight: "700" }}>(Owner Type)</span> &nbsp; <span style={{ fontWeight: "bold" }}> &nbsp; : &nbsp;&nbsp; </span> <h6 className="m-0" style={{ fontSize: 16, marginLeft: 5, fontWeight: "400" }}>{data.owner_type}</h6>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="details_items d-flex my-3" style={{ flexDirection: "row", alignItems: "center" }}>
                                                    <GoHome style={{ fontSize: 26 }} /><span style={{ fontSize: 12, fontWeight: "700" }}>(Rooms)</span> &nbsp; <span style={{ fontWeight: "bold" }}> &nbsp; : &nbsp;&nbsp; </span> <h6 className="m-0" style={{ fontSize: 16, marginLeft: 5, fontWeight: "400" }}>{data.rooms}</h6>
                                                </div>
                                                <div className="details_items d-flex my-3" style={{ flexDirection: "row", alignItems: "center" }}>
                                                    <BiBath style={{ fontSize: 26 }} /><span style={{ fontSize: 12, fontWeight: "700" }}>(Bathrooms)</span> &nbsp; <span style={{ fontWeight: "bold" }}> &nbsp; : &nbsp;&nbsp; </span> <h6 className="m-0" style={{ fontSize: 16, marginLeft: 5, fontWeight: "400" }}>{data.bathrooms}</h6>
                                                </div>
                                            </>
                                        )}

                                        <div className="details_items d-flex my-3" style={{ flexDirection: "row", alignItems: "center" }}>
                                            <TiChartAreaOutline style={{ fontSize: 26 }} /><span style={{ fontSize: 12, fontWeight: "700" }}>(Area)</span> &nbsp; <span style={{ fontWeight: "bold" }}> &nbsp; : &nbsp;&nbsp; </span> <h6 className="m-0" style={{ fontSize: 16, marginLeft: 5, fontWeight: "400" }}>{data.area} Sq/ft</h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="pricing d-flex align-items-center">
                                                <h3 style={{ fontSize: 30, fontWeight: "400", color: "#f51f8a", margin: "0" }}>₹&nbsp;</h3><p className="d-flex align-items-end" style={{ fontWeight: "700", fontSize: 24 }}>{formatAmount(Number(data.dealer_price))}<p style={{ fontSize: 8, marginBottom: 7 }}>{data.p_goal == "Rent" ? ("Per/Month") : ("Only For")}</p></p>
                                            </div>
                                            <div className="discription mt-2">
                                                <h2>Discription</h2>
                                                <p>{data.details}</p>
                                            </div>
                                        </div>
                                        <div className="col-4 d-flex justify-content-center align-items-center">
                                            <a href="JavaScript:void(0)" data-toggle="modal" data-target="#exampleModalCenter" className="buttons__primaryRegular"><span>I Am Interested</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <ToastContainer />
                </>
            )
        }
    }
}

export default withRouter(Rent);