
import React,{Component} from 'react';
import URL from '../URL'
import { withRouter ,Link} from 'react-router-dom';
import * as NumericInput from "react-numeric-input";
import Cookies from 'universal-cookie';
const cookies = new Cookies();


class OurPartner extends Component{
  constructor(props) {

    super(props);
   
      this.state = {
        PartnersList:[]
      }
    

      this.handleNext = this.handleNext.bind(this);


    }




    async  componentDidMount() {

      await  this.fetchPartnersList()
   
       
     }
   
     fetchPartnersList() {
       fetch(URL + "/APP-API/Product/GetPartnersList", {
         method: 'post',
         header: {
           'Accept': 'application/json',
           'Content-type': 'application/json'
         },
         body: JSON.stringify({
   
   
         })
   
       })
         .then((response) => response.json())
         .then((responseJson) => {
   
   
            console.log('PartnersList',responseJson)
   
           this.setState({ PartnersList: responseJson.data, inMemoryrestorentData: responseJson.data });
   
   
   
   
   
         })
         .catch((error) => {
           //  console.error(error);
   
         });
   
   
     }
   
   

    render() { 



    
    return (
    
      <React.Fragment>
    <div class="section-space40 bg-white">
        <div class="container">
            <div class="row">
                                       {this.state.PartnersList.slice(0, 8).map((item, key) => {
                        return (


                          <React.Fragment>

                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-6"> <img src={URL + "/image/loan_icon/" + item.images} style={{ width: 100, height: 60 }}  /> </div>
                  </React.Fragment>





                        )
                      })}

            </div>
        </div>

        
    </div>
	<div class="row">
          <div class="offset-xl-2 col-xl-8 offset-md-2 col-md-8 offset-md-2 col-md-8 col-sm-12 col-12 text-center my-4"> <a href="#!" class="btn btn-dark"><Link to={{ pathname: '/partner' }} > See all Partners </Link></a> </div>
            </div>
      </React.Fragment>

        )};

        handleNext(loanId, loanName) {
          const Routation = "/Loan/"+loanId + "/" + loanName;
          this.props.history.push(Routation);
      
        }

  }

export default withRouter(OurPartner);
