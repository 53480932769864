import React, { Component } from 'react';
import { Redirect ,Link,withRouter} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';
import URL from '../URL'

const cookies = new Cookies()


class GetQuote extends Component{
  constructor(props) {

    super(props);

      this.state = { 
        isClickedAdd:false,
        UserPhone:'',
        UserFullName:'',
        email:'',
        loantype:'',
        loanTime:'',
        loanAmount:'',
        UserID:null,
        Categorys:[]
      }
      this.handleNext = this.handleNext.bind(this);
      this.handleBack = this.handleBack.bind(this); 

      this.onChange = this.onChange.bind(this);

  }

  handleBack() {

    this.props.history.goBack();

  }

  handleNext(Routation)
  {
    this.props.history.push(Routation);
  }

  onChange(e) {

            if (e.target.id === 'name') {
            this.setState({ UserFullName: e.target.value });
            }else if (e.target.id === 'mobile') {
            this.setState({ UserPhone: e.target.value});
            }else if (e.target.id === 'loantype') {
            this.setState({ loantype: e.target.value});
            }else if (e.target.id === 'email') {
            this.setState({ email: e.target.value});
            }else if (e.target.id === 'loanAmount') {
            this.setState({ loanAmount: e.target.value});
            }else if (e.target.id === 'loanTime') {
            this.setState({ loanTime: e.target.value});
            }

}


  async componentDidMount()
  {

    var UserID = await cookies.get('UserID');
      this.setState({UserID})
      this.fetchCategorys()
   

  }

  async fetchCategorys() {
    await  fetch(URL + "/APP-API/Product/GetLoanList", {
        method: 'post',
        header: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
  
  
        })
  
      })
        .then((response) => response.json())
        .then((responseJson) => {
  
  
           console.log('Categorys',responseJson)
  
          this.setState({ Categorys: responseJson.data, inMemoryrestorentData: responseJson.data });
  
          this.setState({ loantype:responseJson.data[0].name});
  
  
  
        })
        .catch((error) => {
          //  console.error(error);
  
        });
  
  
    }



  render() {



    return ( 
<React.Fragment>
<div class="page-header">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="page-breadcrumb">
                        <ol class="breadcrumb">
                            <li><a href="#" onClick={()=>this.props.history.push("/")} >Home</a></li>
                            <li class="active">Get Quote</li>
                        </ol>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="bg-white pinside30">
                        <div class="row align-items-center">
                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                <h1 class="page-title">Get Quote</h1>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <div class="btn-action"> <a href="#!" class="btn btn-secondary">How To Apply</a> </div>
                            </div>
                        </div>
                    </div>
                    <div class="sub-nav" id="sub-nav">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="">
    
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="wrapper-content bg-white p-3 p-lg-5">
                        <div class="contact-form mb60">
                            <div class=" ">
                                <div class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <div class="mb60  section-title text-center  ">
                                    
                                        <h1>Get Quote</h1>
                                        <p>Reach out to us &amp; we will respond as soon as we can.</p>
                                    </div>
                                </div>
                         
                                    <div class=" ">
                                      
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div class="form-group">
                                                    <label class="sr-only control-label" for="name">name<span class=" "> </span></label>
                                                    <input onChange={this.onChange} id="name" name="name" type="text" placeholder="Name" class="form-control input-md" required/>
                                                </div>
                                            </div>
                                           
                                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div class="form-group">
                                                    <label class="sr-only control-label" for="email">Email<span class=" "> </span></label>
                                                    <input onChange={this.onChange} id="email" name="email" type="email" placeholder="Email" class="form-control input-md" />
                                                </div>
                                            </div>
                                         
                                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div class="form-group">
                                                    <label class="sr-only control-label" for="phone">Phone<span class=" "> </span></label>
                                                    <input onChange={this.onChange} id="mobile" name="phone" type="text" placeholder="Phone" class="form-control input-md" required/>
                                                </div>
                                            </div>
                         
                                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div class="form-group">
                          
    <select  onChange={this.onChange} class="form-control" id="loantype">
    {this.state.Categorys.map((item, key) => {
                                            return (
      <option key={key} value={item.name}>{item.name}</option>

      )
                                         })}
   
    </select>
                                                </div>
                                            </div>

                                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div class="form-group">
                                                    <label class="sr-only control-label" for="loanAmount">Loan Amount<span class=" "> </span></label>
                                                    <input onChange={this.onChange} id="loanAmount" name="loanAmount" type="number" placeholder="Loan Amount" class="form-control input-md" required/>
                                                </div>
                                            </div>
                                         
                                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div class="form-group">
                                                    <label class="sr-only control-label" for="loanTime">Loan Time in Year<span class=" "> </span></label>
                                                    <input  onChange={this.onChange} id="loanTime" name="loanTime" type="number" placeholder="Loan Time in Year" class="form-control input-md" required/>
                                                </div>
                                            </div>
                                         
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <button onClick={() => this.SaveAddress()} type="submit" class="btn btn-secondary">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                             
                            </div>
                        </div>
  
                  
                       
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
<ToastContainer />


</React.Fragment>



       
     
    );
  
  }

  async SaveAddress() {



    this.setState({isClickedAdd:true,})

 

    const { UserFullName,UserPhone,email,loantype,loanTime,loanAmount} = this.state;

    var phoneno = /^\d{10}$/;

    if(UserFullName==="" ){
    
      this.setState({isClickedAdd:false,})
     var msg = '**Name Requird';
      toast.error(msg)
    }

    else if(UserPhone===''){
        this.setState({isClickedAdd:false,})
  
        var msg = '** Enter Mobile Number';
        toast.error(msg)
       
      }


    else if(loantype===''){
      this.setState({isClickedAdd:false,})

      var msg = '** Plaese Select Loan Type';
      toast.error(msg)
     
    }

 

    else if(loanAmount===''){
      this.setState({isClickedAdd:false,})

      var msg = '** Enter Loan Amount';
      toast.error(msg)
     
    }

   
    else if(loanTime===''){
        this.setState({isClickedAdd:false,})
  
        var msg = '** Enter Loan Time in Year';
        toast.error(msg)
       
      }
  
   
    else{


      
    this.setState({isClickedAdd:true})
    fetch(URL+"/APP-API/App/insertLoanLeads",{
      method:'post',
      header:{
        'Accept': 'application/json',
        'Content-type': 'application/json'
      },
      body:JSON.stringify({

        
          UserFullName:UserFullName,
          UserPhone:UserPhone,
          email:email,
          loantype:loantype,
          loanTime:loanTime,
          loanAmount:loanAmount,
          type: 'Loan'

        
      })
      

  }) .then((response) => response.json())
  .then((responseJson) => {

    this.setState({isClickedAdd:false})
  
    
   console.log(responseJson)

   if(responseJson.status=='done')
{

  var msg = 'Your Quote registerd successfully'
    toast.success(msg)
    


}



  })
  .catch((error) => {
    //  console.error(error);
       
  });

    }
  
      
  

  }



}

export default withRouter(GetQuote);