import React from 'react';
import { Link} from "react-router-dom";
 

function SplshImage() {
    return (
  //   	<div id="preloader">
	// 	<div id="status">&nbsp;</div>
  // </div>
  <>
  </>
        )
  }

export default SplshImage;
