import React, { Component } from 'react';
import SplshImage from './SplshImage';

import { Redirect ,withRouter} from 'react-router-dom';
import URL from '../URL'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class HomeSlider extends Component{
  constructor(props) {

    super(props);

      this.state = {
        isloaded:false,
        UserID:null,
        sliderData:[]
      }
      this.splashScreen = this.splashScreen.bind(this);

  }

splashScreen()
{





}

  async componentDidMount()
  {

  
    

       fetch(URL+"/APP-API/Product/getSlider",{
      method:'post',
      header:{
        'Accept': 'application/json',
        'Content-type': 'application/json'
      },
      body:JSON.stringify({

       type:'Home'
       
      })
      
    })
     .then((response) => response.json())
     .then((responseJson) => {
       
      
        //  console.log('sliderData',responseJson.data)
  
      this.setState({sliderData:responseJson.data,isloaded:true});

      // this.fetcProductByCategory()
    
    
    })
   
     .catch((error) => {
       //  console.error(error);
          
     });

  }




  render() {



    return ( <React.Fragment>
		<div class="slider" id="slider">
      

        <Carousel 
        showArrows={true} showThumbs={false} showStatus={true}
        autoPlay={true}> 
        { this.state.sliderData.map((item, key) => {
            return (
    
                <div class="slider-img"><img src={URL+"/image/banner-image/"+item.image} alt={item.title} class=""/>
                <div class="container">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="slider-captions">
                           
                            <h1 class="slider-title">{item.title}</h1>
                            <p class="slider-text d-none d-xl-block d-lg-block d-md-block">{item.decreption	}</p>
                            <a href="loan-listing-image.html" class="btn btn-secondary ">{item.button_name}</a>
                        </div>
                        
                    </div>
                </div>
            </div>
          )
        })}
        </Carousel>

     
      
    </div>
      </React.Fragment>);
  

}

}

export default withRouter(HomeSlider);