import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router';
import Cookies from 'universal-cookie';
import ImageUploader from 'react-images-upload';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import URL from '.././URL';

const cookie = new Cookies();

class AddRent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            UserID: null,
            property_type: 'Rent',
            file: null,
            furnished: 'Furnished',
            bedroom: '1',
            bathroom: '1',
            kitchen: '1',
            maintenance: '',
            dealer_price: '',
            floorNumber: '',
            carParking: 'Yes',
            facing: 'East',
            roadSide: 'Yes',
            pricingType: 'Month',
            discription: '',
            address: '',
            bachelors_allowd: 'Yes',
            locality: '',
            area: '',
            BHK: '1 BHK',
            title: '',
            city: '',
            state: '',
            zip: '',
            p_type: 'Flat / Apartment'
        }
    }

    onSubmit = async (e) => {
        e.preventDefault();
        this.setState({ isLoading: true });
        const { address, zip, city, property_type, file, price, furnished, locality } = this.state;

        if (property_type === '') {
            this.setState({ isLoading: false, })
            var msg = '** Please Select property type ';
            toast.error(msg)
        } /* else if (price === '') {
            this.setState({ isLoading: false, })
            var msg = '** Price Requird';
            toast.error(msg)
        } else if (furnished === '') {
            this.setState({ isLoading: false, })
            var msg = '** Please Select furnished status';
            toast.error(msg)
        } else if (address === '') {
            this.setState({ isLoading: false, })
            var msg = '** Adreess Requird';
            toast.error(msg)
        } else if (locality === '') {
            this.setState({ isLoading: false, })
            var msg = '** Locality Requird';
            toast.error(msg)
        } else if (zip === '') {
            this.setState({ isLoading: false, })
            var msg = '** Enter Zip';
            toast.error(msg)
        } else if (city === '') {
            this.setState({ isLoading: false, })
            var msg = '** Enter City';
            toast.error(msg)
        } else if (file === null) {
            this.setState({ isLoading: false, })
            var msg = '** Please upload photos';
            toast.error(msg)
        } */ else {
            console.log("ok");
            const res = await this.SaveAddress(this.state.file);

            console.log(res);

            /*  if (res.data.status == 'success') {
                 this.handleNext("/rent");
             } else {
                 var msg = 'Something Went Wrong!! Please after sometime.';
                 toast.error(msg)
             } */
        }
    }

    onChange = (e) => {
        if (e.target.id === 'type') {
            this.setState({ property_type: e.target.value });
        } else if (e.target.id === 'BHK') {
            this.setState({ BHK: e.target.value });
        } else if (e.target.id === 'title') {
            this.setState({ title: e.target.value });
        } else if (e.target.id === 'bedroom') {
            this.setState({ bedroom: e.target.value });
        } else if (e.target.id === 'bathroom') {
            this.setState({ bathroom: e.target.value });
        } else if (e.target.id === 'kitchen') {
            this.setState({ kitchen: e.target.value });
        } else if (e.target.id === 'furnished') {
            this.setState({ furnished: e.target.value });
        } else if (e.target.id === 'bachelor') {
            this.setState({ bachelors_allowd: e.target.value });
        } else if (e.target.id === 'maintenance') {
            this.setState({ maintenance: e.target.value });
        } else if (e.target.id === 'area') {
            this.setState({ area: e.target.value });
        } else if (e.target.id === 'dealer_price') {
            this.setState({ dealer_price: e.target.value });
        } else if (e.target.id === 'floorNumber') {
            this.setState({ floorNumber: e.target.value });
        } else if (e.target.id === 'carParking') {
            this.setState({ carParking: e.target.value });
        } else if (e.target.id === 'facing') {
            this.setState({ facing: e.target.value });
        } else if (e.target.id === 'roadSide') {
            this.setState({ roadSide: e.target.value });
        } else if (e.target.id === 'price') {
            this.setState({ price: e.target.value });
        } else if (e.target.id === 'pricingType') {
            this.setState({ pricingType: e.target.value });
        } else if (e.target.id === 'discription') {
            this.setState({ discription: e.target.value });
        } else if (e.target.id === 'address') {
            this.setState({ address: e.target.value });
        } else if (e.target.id === 'locality') {
            this.setState({ locality: e.target.value });
        } else if (e.target.id === 'city') {
            this.setState({ city: e.target.value });
        } else if (e.target.id === 'state') {
            this.setState({ state: e.target.value });
        } else if (e.target.id === 'zip') {
            this.setState({ zip: e.target.value });
        }
    }

    componentDidMount = async () => {
        console.log(URL);
        this.setState({ UserID: await cookie.get("UserID") });
        console.log(this.state.UserID);
    }

    handleNext = (props) => {
        this.props.history.push(props);
    }

    render() {
        return (
            <>
                <div class="hero-home-loan">
                    <div class="container">
                        <div class="row">
                            <div class="offset-xl-2 col-xl-8 offset-lg-1 col-lg-10 offset-md-1 col-md-10 col-sm-12 col-12">
                                <div class="request-form">
                                    <h2 class="form-title">Add Your Room</h2>
                                    <form class="form-row" onSubmit={this.onSubmit}>
                                        <div class="form-group mb-1 col-md-4">
                                            <select onChange={this.onChange} id="type" class="custom-select" title="Select Type" style={{ height: 52 }}>
                                                <option value="Rent">Rent</option>
                                                <option value="Sell">Sell</option>
                                            </select>
                                        </div>
                                        <div class="form-group mb-1 col-md-4">
                                            <label class="control-label sr-only" for="name">Category</label>
                                            <select onChange={this.onChange} id="Category" name="Category" class="form-control">
                                                <option value="Flat / Apartment">Flat / Apartment</option>
                                                <option value="Residential House">Residential House</option>
                                                <option value="Residential Land/ Plot">Residential Land/ Plot</option>
                                                <option value="Commercial Land">Commercial Land</option>
                                                <option value="Warehouse/ Godown">Warehouse/ Godown</option>
                                                <option value="Commercial Shop">Commercial Shop</option>
                                            </select>
                                        </div>
                                        <div class="form-group mb-1 col-md-4">
                                            <label class="control-label sr-only" for="name">BHK</label>
                                            <select onChange={this.onChange} id="BHK" name="BHK" class="form-control">
                                                <option value="1 BHK">1 BHK</option>
                                                <option value="2 BHK">2 BHK</option>
                                                <option value="3 BHK">3 BHK</option>
                                                <option value="3+ BHK">3+ BHK</option>
                                            </select>
                                        </div>
                                        <div class="form-group mb-1 col-md-12">
                                            <label class="control-label sr-only" for="name">Title</label>
                                            <input onChange={this.onChange} id="title" name="Title" type="text" placeholder="Title" class="form-control input-md" required="" />
                                        </div>
                                        <div class="form-group mb-1 col-md-4">
                                            <label class="control-label" for="phone">Bedroom</label>
                                            <select onChange={this.onChange} id="bedroom" name="Bedroom" class="form-control">
                                                <option value="1">1 Bedroom</option>
                                                <option value="2">2 Bedroom</option>
                                                <option value="3">3 Bedroom</option>
                                                <option value="3+">3+ Bedroom</option>
                                            </select>
                                        </div>
                                        <div class="form-group mb-1 col-md-4">
                                            <label class="control-label" for="phone">Bathroom</label>
                                            <select onChange={this.onChange} id="bathroom" name="Bathroom" class="form-control">
                                                <option value="1">1 Bathroom</option>
                                                <option value="2">2 Bathroom</option>
                                                <option value="3">3 Bathroom</option>
                                                <option value="3+">3+ Bathroom</option>
                                            </select>
                                        </div>
                                        <div class="form-group mb-1 col-md-4">
                                            <label class="control-label" for="phone">Area <span style={{ fontSize: 8 }}> in sq/ft</span></label>
                                            <input onChange={this.onChange} type="number" id="area" required class="form-control" />
                                        </div>
                                        <div class="form-group mb-1 col-md-4">
                                            <label class="control-label" for="phone">Price</label>
                                            <input onChange={this.onChange} type="number" id="dealer_price" required class="form-control" />
                                        </div>
                                        <div class="form-group mb-1 col-md-12">
                                            <label class="control-label sr-only" for="discription">Discription</label>
                                            <input onChange={this.onChange} id="discription" name="discription" type="text" placeholder="Discription" class="form-control input-md" required="" />
                                        </div>
                                        <div class="form-group mb-1 col-md-12">
                                            <label class="control-label sr-only" for="address">Address</label>
                                            <input onChange={this.onChange} id="address" name="address" type="text" placeholder="Address" class="form-control input-md" required="" />
                                        </div>
                                        <div class="form-group mb-1 col-md-3">
                                            <label class="control-label" for="phone">City</label>
                                            <input onChange={this.onChange} type="text" id="city" required class="form-control" />
                                        </div>
                                        <div class="form-group mb-1 col-md-3">
                                            <label class="control-label" for="phone">Pin Code</label>
                                            <input onChange={this.onChange} type="number" id="zip" required class="form-control" />
                                        </div>
                                        <div class="form-group mb-1 mt-2 col-md-12">
                                            <ImageUploader
                                                withIcon={true}
                                                buttonText='Choose Images'
                                                onChange={this.onChangeImage}
                                                imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                                                maxFileSize={5242880}
                                                singleImage={false}
                                                withPreview={true}
                                            />
                                        </div>
                                        <div class="form-group mb-1 col-md-12 mt-4">
                                            <button type="submit" class="btn btn-secondary btn-block">List Now</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </>
        )
    }

    onChangeImage = (pictureFiles) => {
        this.setState({
            file: pictureFiles,
        });
    }

    async SaveAddress(files) {

        const formData = new FormData();

        console.log(this.state.file[0]);

        formData.append(`file`, this.state.file[0], this.state.file[0].name);
        formData.append('UserID', this.state.UserID);
        formData.append('roal_type', this.state.roal_type);
        formData.append('p_goal', this.state.property_type)
        formData.append('p_type', this.state.p_type)
        formData.append('BHK', this.state.BHK)
        formData.append('rooms', this.state.bedroom)
        formData.append('bathrooms', this.state.bathroom)
        // formData.append('kitchen', this.state.kitchen)
        // formData.append('furnished', this.state.furnished)
        // formData.append('bachelorAllowed', this.state.bachelors_allowd)
        // formData.append('maintenance', this.state.maintenance)
        formData.append('area', this.state.area)
        formData.append('dealer_price', this.state.dealer_price)
        // formData.append('floorNo', this.state.floorNumber)
        // formData.append('carParking', this.state.carParking)
        // formData.append('facing', this.state.facing)
        // formData.append('roadSide', this.state.roadSide)
        formData.append('heading', this.state.title)
        formData.append('details', this.state.discription)
        // formData.append('price', this.state.price)
        // formData.append('priceType', this.state.pricingType)
        formData.append('address_1', this.state.address)
        // formData.append('locality', this.state.locality)
        formData.append('city', this.state.city)
        // formData.append('state', this.state.state)
        formData.append('pin_code', this.state.zip)

        await fetch(URL + "/APP-API/Partner/InsertRealEsateData", {
            method: 'post',
            header: {
                'Accept': 'application/json',
                // 'Content-type': 'application/json'
                'Content-Type': 'multipart/form-data'

            },
            body: formData
        })
            .then((response) => response.json())
            .then((responseJson) => {

                // console.log(responseJson)

                this.setState({ isloading: false })
                if (responseJson.msg == 'InsertAndMove') {
                    this.props.history.push("/")
                    //   alert('yes')
                }
                else {
                    alert('Something Went wrong')
                }


            })
            .catch((error) => {
                console.error(error);
            });

        /* return await axios.post(URL + "/APP-API/Partner/InsertRealEsateData", formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }); */

    }




}

export default withRouter(AddRent);