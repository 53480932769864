import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiLoader4Line } from 'react-icons/ri';
import Cookies from 'universal-cookie';
import URL from '../URL'

const cookies = new Cookies()


class SignUp extends Component {
  constructor(props) {

    super(props);

    this.state = {
      isloading: false,
      isClickedShinUp: false,
      fullname: '',
      email: '',
      mobile: '',
      city: '',
      occupation: '',
      password: '',
      referral_code: '',
      roal_type: 'Customer',
      acoount_type: 'Loan'
    }
    this.onChange = this.onChange.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);


  }
  handleBack() {

    this.props.history.goBack();

  }

  handleNext(Routation) {
    this.props.history.push(Routation);
  }


  onChange(e) {
    if (e.target.id === 'name') {
      this.setState({ fullname: e.target.value });
    } else if (e.target.id === 'mobile') {
      this.setState({ mobile: e.target.value });
    } else if (e.target.id === 'password') {
      this.setState({ password: e.target.value });
    } else if (e.target.id === 'city') {
      this.setState({ city: e.target.value });
    } else if (e.target.id === 'email') {
      this.setState({ email: e.target.value });
    } else if (e.target.id === 'Occupation') {
      this.setState({ occupation: e.target.value });
    } else if (e.target.id === 'ReferralCode') {
      this.setState({ referral_code: e.target.value });
    }
  }


  async componentDidMount() {


  }




  render() {


    return (
      <>
        <div className="baap">
          <div class="main" style={{ width: "500px", marginTop: "2rem", marginBottom: "2rem" }}>
            <p class="sign" align="center">CREATE ACCOUNT</p>
            <form class="form1">
              <input type="text" onChange={this.onChange} align="center" class="un" id="name" placeholder="Name" required="" />
              <input type="email" onChange={this.onChange} align="center" class="un" id="email" placeholder="Email" required="" />
              <input type="number" onChange={this.onChange} class="un" id="mobile" placeholder="Mobile Number" required="" />
              <input type="text" onChange={this.onChange} class="un" id="city" placeholder="City" required="" />
              <input type="text" onChange={this.onChange} class="un" id="Occupation" placeholder="Occupation / Job Title" required="" />
              <input type="password" onChange={this.onChange} class="un" id="password" placeholder="Enter your password" required="" />
              <input type="text" onChange={this.onChange} class="un" id="ReferralCode" placeholder="Referral Code (Optional)" required="" />
              <div className="d-flex justify-content-center">
                <a class="submit ml-0" onClick={() => this.CheackMobileOfUser()} align="center">{this.state.isloading ? (<RiLoader4Line className="rotate" />) : "Sign Up"}</a>
              </div>
              <p class="signUp" style={{ color: "rgb(189 189 189)", fontWeight: "700", marginTop: 15, marginBottom: 15 }} align="center">Already have an account? <Link to="/Login" style={{ fontWeight: "500" }}>Log In.</Link></p>
            </form>
          </div>
        </div>
      </>

    );

  }

  async CheackMobileOfUser() {

    this.setState({ isClickedShinUp: true, errorr: '', isloading: true })

    var mobileno = /^\d{10}$/;
    if (this.state.mobile == "") {

      this.setState({ isloading: false, })
      var msg = 'Please fill mobile number'
      toast.error(msg)

    } else if (!this.state.mobile.match(mobileno)) {
      this.setState({ isloading: false, })

      var msg = 'Please Enter Vaild 10 digit mobile Number'
      toast.error(msg)

    } else if (this.state.fullname == '') {
      this.setState({ isloading: false, })
      var msg = 'Please Enter Name'
      toast.error(msg)

    } else if (this.state.password == '') {

      this.setState({ isloading: false, })
      var msg = 'Password Requird'
      toast.error(msg)

    } else {
      this.insertUserFun();
    }


  }

  sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  insertUserFun() {
    fetch(URL + "/APP-API/Partner/Add", {
      method: 'post',
      header: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        name: this.state.fullname,
        email: this.state.email,
        phone: this.state.mobile,
        password: this.state.password,
        city: this.state.city,
        occupation: this.state.occupation,
        roal_type: this.state.roal_type,
        referral_code: this.state.referral_code,
        acoount_type: this.state.acoount_type,
      })


    }).then((response) => response.json())
      .then((responseJson) => {
        this.setState({ isloading: false })

        console.log("json", responseJson);

        if (responseJson.email == 'exists') {

          var msg = 'Allready Exists!';
          toast.error(msg);
          this.sleep(2000);
          this.handleNext("/Login");


        }
        else if (responseJson.phone == 'exists') {

          var msg = "This Mobile Number is already in use";
          toast.error(msg);

        }
        else if (responseJson.code == 'invaild') {

          var msg = "Referral Code does not match";
          toast.error(msg);

          this.setState({ referral_code: '' })

        }
        else if (responseJson.SignUp == 'done') {

          // alert("success")

          //  AsyncStorage.setItem('Auth', 'logged');
          var msg = 'You have registerd successfully'
          toast.success(msg)
          this.sleep(2000)
          cookies.set("isLogged", true, { maxAge: 999999999999 });
          cookies.set("UserID", responseJson.data[0].id, { maxAge: 999999999999 });
          cookies.set("user_mobile_number", responseJson.data[0].mobile, { maxAge: 999999999999 });
          cookies.set("user_name", responseJson.data[0].name, { maxAge: 999999999999 });
          cookies.set("roal_type", responseJson.data[0].roal_type, { maxAge: 999999999999 });
          this.handleNext("/")
        }
        else {
          var msg = "Something went wrong";
          toast.error(msg);
        }

      })
      .catch((error) => {
        console.error(error);

      });
  }

}

export default withRouter(SignUp);