import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Loan extends Component {

    constructor(props){
        super(props);

        this.state = {
            loanName : null,
            loanId : null,
            loanType:'',
            defination : [],
            whyShuldTake : [],
            howToApply : [],
            eligibility : [],
            requirdDocument : [],
            banks : [],
            extraFeatures : [],
            faq : [],
            isloaded : false,
            UserPhone: '',
            UserFullName: '',
            email: '',
            loanTime: '',
            loanAmount: '',
        }
        this.onChange = this.onChange.bind(this);

    }

    onChange(e) {

        if (e.target.id === 'name') {
            this.setState({ UserFullName: e.target.value });
        } else if (e.target.id === 'mobile') {
            this.setState({ UserPhone: e.target.value });
        } else if (e.target.id === 'email') {
            this.setState({ email: e.target.value });
        } else if (e.target.id === 'loanAmount') {
            this.setState({ loanAmount: e.target.value });
        } else if (e.target.id === 'loanTime') {
            this.setState({ loanTime: e.target.value });
        }

    }


  async  componentDidUpdate(prevProps) {
        if (this.props.match.params.loanName !== prevProps.match.params.loanName) {
            this.setState({ isloaded: false })

            await this.setState({
                loanId: this.props.match.params.loanId,
                loanName: this.props.match.params.loanName,
                loanType: this.props.match.params.loanType
            });

            this.fetchloanDetails()

        }
    }

    async componentDidMount() {


        await this.setState({ 
            loanId : this.props.match.params.loanId,
            loanName : this.props.match.params.loanName,
            loanType: this.props.match.params.loanType
        });

      await  this.fetchloanDetails();

    }

    fetchloanDetails() {

     

        fetch(URL+"/APP-API/Product/getLoanKeyFeatures",{
            method:'post',
            header:{
              'Accept': 'application/json',
              'Content-type': 'application/json'
            },
            body:JSON.stringify({
      
             loan_id : this.state.loanId
             
            })
            
          })
           .then((response) => response.json())
           .then((responseJson) => {
             
            

        
            this.setState({

                defination : responseJson.defination,
                whyShuldTake : responseJson.whyShuldTake,
                howToApply : responseJson.howToApply,
                eligibility : responseJson.eligibility,
                requirdDocument : responseJson.requirdDocument,
                banks : responseJson.banks,
                extraFeatures : responseJson.extraFeatures,
                faq : responseJson.faq,

                          });
      
            // this.fetcProductByCategory()
          
          
          })
         
           .catch((error) => {
             //  console.error(error);
                
           });
    }

    render() {


        return (
            <>

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.match.params.loanName} - First Loan Finance Service</title>
                    <link rel="canonical" href="http://mysite.com/example" />
                    <link rel="icon" type="image/png" href="/assets/img/favicon.png" />

                    <meta name="description" content="First Loan Financial Services is a best services provider of all types of loan, Home Loan in Gorakhpur, Personal Loan in Gorakhpur and Property Loan in Gorakhpur" />
                    <meta name="keywords" content="First Loan Financial Services is a best services provider of all types of loan, Home Loan in Gorakhpur, Personal Loan in Gorakhpur and Property Loan in Gorakhpur" />
                    <meta name="author" content="First Loan Finance Service" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />


                </Helmet>
                <div class="page-header">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="page-breadcrumb">
                                    <ol class="breadcrumb">
                                        <li><a href="" onClick={()=>this.props.history.push("/")}>Home</a></li>
                                    
                                        <li class="active">{this.state.loanName}</li>
                                    </ol>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="bg-white pinside30">
                                    <div class="row align-items-center">
                                        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-8">
                                            <h1 class="page-title">{this.state.loanName}</h1>
                                        </div>
                                        <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-4">
                                            <div class="row">
                                                <div class="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                                                  
                                                </div>
                                                <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                                                    <div class="btn-action d-none d-md-block d-lg-block"> <a href="#"  onClick={()=>this.props.history.push("/contact")}  class="btn btn-primary">Get a call back</a>  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sub-nav" id="sub-nav">
                                    <ul class="text-center nav nav-justified d-flex flex-column flex-md-row flex-lg-row ">
                                        <li class="nav-item"><a href="#section-about" class="page-scroll nav-link">About </a></li>
                                        <li class="nav-item"><a href="#section-typeloan" class="page-scroll nav-link">document</a></li>
                                        <li class="nav-item"><a href="#section-feature" class="page-scroll nav-link">Benefits</a></li>
                                        {this.state.loanType=='Loan'?(
                                            <li class="nav-item"><a href="#section-eleigiblity" class="page-scroll nav-link">eligibility</a></li>
                                        ):null}
                                      
                                        <li class="nav-item"><a href="#section-faq" class="page-scroll nav-link">FAQ’S</a></li>
                                        <li class="nav-item"><a href="#section-apply" class="page-scroll nav-link">Apply now</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" ">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="wrapper-content bg-white">
                        <div class="section-scroll pinside60" id="section-about">
                            <h1>About {this.state.loanName}</h1>
                              {this.state.defination.map((item, key) => {
                                   return (
                                    <p class={key==0?"lead":null}> {item.features} </p>

                                    )
                                            })}

                          
                        </div>

                        <div class="section-scroll" id="section-typeloan">
                            <div class="bg-light p-5">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="section-title mb60">
                                            <h2>Why Should You Take {this.state.loanName}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                {this.state.whyShuldTake.map((item, key) => {
                                   return (
                                  
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="feature-blurb mb60">
                                            <h3> {item.keys}</h3>
                                            <p>{item.features}</p>
                                        </div>
                                    </div>

                                    )
                                            })}

                                 
                                  
                                </div>
                            </div>
                        </div>

                                    {this.state.loanType == 'Loan' ? (
                                        <div class="section-scroll" id="section-feature">
                                            <div class="pinside60">
                                                <div class="row">
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div class="section-title mb60">
                                                            <h2>How To Apply {this.state.loanName}</h2>
                                                            <p> All loans are not created equal, {this.state.loanName} has become a great option for people to use.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">


                                                    {this.state.howToApply.map((item, key) => {
                                                        return (

                                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <div class="feature-icon mb30">
                                                                    <div class="icon mb20"><i class="icon-clock icon-default icon-2x"></i></div>
                                                                    <h3>{item.keys}</h3>
                                                                    <p>{item.features}</p>
                                                                </div>
                                                            </div>

                                                        )
                                                    })}



                                                </div>
                                            </div>
                                        </div>
                                    ) : null}

                    


                        <div class="section-scroll" id="section-typeloan">
                            <div class="bg-light p-5">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="section-title mb60">
                                            <h2>Document Required for {this.state.loanName}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                {this.state.requirdDocument.map((item, key) => {
                                   return (
                                  
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div class="feature-blurb mb60">
                                            <h3>{item.keys}</h3>
                                            <p>{item.features}</p>
                                        </div>
                                    </div>

                                    )
                                            })}

                                 
                                  
                                </div>
                            </div>
                        </div>


                        {this.state.loanType=='Loan'?(

                                        <div class="section-scroll" id="section-eleigiblity">
                                            <div class=" bg-light p-5">
                                                <div class="row">
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div class="section-title mb60">
                                                            <h2>{this.state.loanName} - Eligibility</h2>
                                                            <p> Any salaried, self-employed or professional Public and Privat companies, Government sector employees including Public Sector is eligible for a {this.state.loanName}. </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">

                                                    {this.state.eligibility.map((item, key) => {
                                                        return (
                                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                                                <div class="feature-blurb mb30">
                                                                    <h3> {item.keys.replace(/\s+/g, ' ').trim()}</h3>
                                                                    <p>{item.features.replace(/\s+/g, ' ').trim()}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}


                                                </div>
                                                <div class="row">
                                                    <div class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-3 col-md-6 col-sm-offset-3 col-sm-12 col-12"> </div>
                                                </div>
                                            </div>
                                        </div>

                        ):null}


                    



                        <div class="section-scroll" id="section-feature">
                            <div class="pinside60">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="section-title mb60">
                                            <h2>Key And Featurs</h2>
                                            <p> All loans are not created equal, {this.state.loanName} has become a great option for people to use.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">


                                {this.state.extraFeatures.map((item, key) => {
                                   return (
                                  
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div class="feature-icon mb30">
                                           
                                            <h3>{item.keys}</h3>
                                            <p>{item.features}</p>
                                        </div>
                                    </div>

                                    )
                                            })}
                                  
                                 
                                
                                </div>
                            </div>
                        </div>

                        <div class="section-scroll" id="section-eleigiblity">
                            <div class=" bg-light p-5">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="section-title mb60">
                                            <h2>{this.state.loanName} - Banks</h2>
                                           
                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                {this.state.banks.map((item, key) => {
                                   return (
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div class="feature-blurb mb30">
                                            <h3> {item.features.replace(/\s+/g,' ').trim()}</h3>
                                            
                                        </div>
                                    </div>
                                    )
                                            })}

                                   
                                </div>
                                <div class="row">
                                    <div class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-3 col-md-6 col-sm-offset-3 col-sm-12 col-12"> </div>
                                </div>
                            </div>
                        </div>


 
                        <div class="section-scroll" id="section-faq">
                            <div class="pinside60">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="section-title mb60">
                                            <h2>Frequently Ask Questions</h2>
                                            <p>If you have a question that deals with clients, customers or the public in general, there is bound to be a need for the FAQ page. </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="accordion" id="faqExample">

                                        {this.state.faq.map((item, key) => {
                                   return (
                                            <div class="card mb-0">
                                                <div class="card-header" id="faqOne">
                                                    <h4 class="mb-0">
                                                        <a href="#!" class="" data-toggle="collapse" data-target="#faqcollapseOne" aria-expanded="true" aria-controls="faqcollapseOne">
                                                            <i class="fa fa-plus-circle mr-2"></i>{item.keys.replace(/\s+/g,' ').trim()}</a>
                                                    </h4>
                                                </div>
                                                <div id="faqcollapseOne" class={key==0?"collapse show":"collapse"} aria-labelledby="faqOne" data-parent="#faqExample">
                                                    <div class="card-body">
                                                    {item.features.replace(/\s+/g,' ').trim()}
                                                    </div>
                                                </div>
                                            </div>


                                            )
                                            })}

                                            
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


               

                        <div class="section-scroll" id="section-apply">
                            <div class="bg-light p-5">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="section-title mb60 text-center">
                                            <h1>Get a Quote</h1>
                                            <p>Now apply for a Car Loan online, All you need to do is provide your details below application form.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                   
                                            <fieldset>
                                                <div class="row">
                                                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 form-group ">
                                                        <label class="control-label" for="name">Name</label>
                                                        <div class="">
                                                                        <input onChange={this.onChange} id="name" name="name" type="text" placeholder="Name" class="form-control input-md" required />
                                                            <span class="help-block"> </span> </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 form-group ">
                                                        <label class=" control-label" for="email">E-Mail</label>
                                                        <div class="">
                                                                        <input onChange={this.onChange} id="email" name="email"  type="text" placeholder="E-mail" class="form-control input-md" />
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 form-group ">
                                                        <label class="control-label" for="phone">Phone</label>
                                                        <div class="">
                                                                        <input onChange={this.onChange} id="mobile" name="phone" type="text" placeholder="Phone" class="form-control" />
                                                        </div>
                                                    </div>

                                                    
                                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 form-group  ">
{this.state.loanType=='Loan'?(
                                                                        <div>

                                                                            <label for="amount" class="control-label">Loan Amount</label>
                                                                            <input onChange={this.onChange} id="loanAmount" name="loanAmount" type="number" placeholder="Loan Amount" class="form-control input-md"  />

                                                                        </div>
):null}
                                                   
                                                       
                                                        
                                                    </div>
                                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 form-group  ">
                                                                    {this.state.loanType == 'Loan' ? (
                                                                        <div>

                                                                            <label for="amount" class="control-label">Year</label>
                                                                            <input onChange={this.onChange} id="loanTime" name="loanTime" type="number" placeholder="Loan Time in Year" class="form-control input-md"  />

                                                                        </div>
                                                                    ) : null}

                                                  
                                                      
                                                       
                                                    </div>
                                                    <div class="form-group col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12">
                                                        <div class="">
                                                                        <button onClick={() => this.SaveAddress()} id="Submit" name="Submit" class="btn btn-primary btn-block">Submit </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                     
                    </div>
                </div>
            </div>
        </div>
                    <ToastContainer />
    </div>
            </>
        )

    }

    async SaveAddress() {



        this.setState({ isClickedAdd: true, })



        const { UserFullName, UserPhone, email, loantype, loanTime, loanAmount } = this.state;

        var phoneno = /^\d{10}$/;

    

        if (UserFullName === "") {

            this.setState({ isClickedAdd: false, })
            var msg = '**Name Requird';
            toast.error(msg)
        }

        else if (UserPhone === '') {
            this.setState({ isClickedAdd: false, })

            var msg = '** Enter Mobile Number';
            toast.error(msg)

        }


        else if (loanAmount === '' && this.state.loanType == 'Loan') {
            this.setState({ isClickedAdd: false, })

            var msg = '** Enter Loan Amount';
            toast.error(msg)

        }


        else if (loanTime === '' && this.state.loanType == 'Loan') {
            this.setState({ isClickedAdd: false, })

            var msg = '** Enter Loan Time in Year';
            toast.error(msg)

        }



      


  

    


        else {



            this.setState({ isClickedAdd: true })
            fetch(URL + "/APP-API/App/insertLoanLeads", {
                method: 'post',
                header: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({


                    UserFullName: UserFullName,
                    UserPhone: UserPhone,
                    email: email,
                    loantype:this.state.loanName,
                    loanTime: this.state.loanTime,
                    loanAmount: this.state.loanAmount,
                    type: this.state.loanType

                })


            }).then((response) => response.json())
                .then((responseJson) => {

                    this.setState({ isClickedAdd: false })


                    console.log(responseJson)

                    if (responseJson.status == 'done') {

                        var msg = 'Your Quote registerd successfully'
                        toast.success(msg)



                    }



                })
                .catch((error) => {
                    //  console.error(error);

                });

        }




    }


}

export default withRouter(Loan);