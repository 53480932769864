import React, { Component } from 'react';
import { Redirect,withRouter} from "react-router-dom";
import Header from '../component/Header';
import Footer from '../component/Footer';


import AboutUS from '../component/AboutUS';
import {Helmet} from "react-helmet";



class AboutUsPage extends Component{
    
  render() {




  return (
    <React.Fragment>

      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us - First Loan Finance Service</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <link rel="icon" type="image/png" href="/assets/img/favicon.png" />

        <meta name="description" content="First Loan Financial Services is a best services provider of all types of loan, Home Loan in Gorakhpur, Personal Loan in Gorakhpur and Property Loan in Gorakhpur" />
        <meta name="keywords" content="First Loan Financial Services is a best services provider of all types of loan, Home Loan in Gorakhpur, Personal Loan in Gorakhpur and Property Loan in Gorakhpur" />
        <meta name="author" content="First Loan Finance Service" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />


      </Helmet>
    


    <Header/>
  
 


    <AboutUS/>

   <Footer/>

    </React.Fragment>
   );
}

}

export default withRouter(AboutUsPage);
