import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import $ from 'jquery';
import URL from '../URL'
import GetSubCategory from './GetSubCategory'
import Cookies from 'universal-cookie';

const cookie = new Cookies();

class Header extends Component {
    constructor(props) {

        super(props);

        this.state = {
            Categorys: []
        }

        // this.onChange = this.onChange.bind(this);
        this.handleNext = this.handleNext.bind(this);

    }



    handleNext(loanId, loanName, loanType) {
        const Routation = "/procuct-details/" + loanId + "/" + loanName + "/" + loanType;
        this.props.history.push(Routation);

    }


    async componentDidMount() {

        await this.fetchCategorys()


    }

    async fetchCategorys() {

        await fetch(URL + "/APP-API/Product/GetLoanList", {
            method: 'post',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({

                type: 'Loan'

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {


                // console.log('Categorys123', responseJson)

                this.setState({ Categorys: responseJson.data, inMemoryrestorentData: responseJson.data });





            })
            .catch((error) => {
                //  console.error(error);

            });


    }


    openDotMenu() {
        $(".others-option-for-responsive .container .container").toggleClass("active");
    }

    render() {


        return (

            <React.Fragment>
                <div class="header-topbar d-none">

                    <div class="container">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 ">
                                <p class="mail-text">Welcome to First Loan Financial Services</p>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                                <p class="mail-text text-center">Call us at 1-800-123-4567</p>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                                <p class="mail-text text-center">Mon to fri 10:00am - 06:00pm</p>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 ">
                                <p class="mail-text text-center">Get started today</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sticky-top bg-white">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">

                                <div class="logo">
                                    <a href="Javascript:void(0)" onClick={() => this.props.history.push("/")}><img src="/images/LOGO.jpg" alt="First Loan Financial Services" style={{ height: "80px" }} /></a>
                                </div>
                            </div>

                            <div class="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6 ">
                                <div class="header-action d-flex justify-content-end">
                                    <a href="#!" onClick={() => this.props.history.push("/LoanCalculator")} class="btn btn-primary btn-sm  d-none d-xl-block d-lg-block mr-2">Loan Calculator</a>
                                    <a href="#!" onClick={() => this.props.history.push("/eligibilyCalc")} class="btn btn-secondary btn-sm ">Elgiblity Calculator</a></div>
                            </div>
                        </div>
                    </div>
                    <nav class="navbar navbar-expand-lg navbar-light bg-white py-3 py-lg-0 border-top border-bottom">
                        <div class="container">
                            <a class="d-xl-none d-lg-none" href="#!">Menu</a>
                            <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="icon-bar top-bar mt-0"></span>
                                <span class="icon-bar middle-bar"></span>
                                <span class="icon-bar bottom-bar"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav nav-justified w-lg-75 mt-2 mt-lg-0">
                                    <li class="nav-item dropdown border-left-lg">
                                        <a class="nav-link dropdown-toggle" id="navbarHome" data-toggle="dropdown" href="#!" onClick={() => this.props.history.push("/")} aria-haspopup="true" aria-expanded="false">
                                            Home
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-lg" aria-labelledby="navbarHome">
                                            <div class="row no-gutters">
                                                <div class="col-6 col-lg-6">

                                                    <h6 class="dropdown-header">
                                                        Loan Services
                                                    </h6>

                                                    {this.state.Categorys.map((item, key) => {
                                                        return (

                                                            <React.Fragment>

                                                                {item.type == 'Loan' ? (
                                                                    <a class="dropdown-item" href="#" onClick={() => this.handleNext(item.id, item.name, item.type)} >
                                                                        {item.name}
                                                                    </a>

                                                                ) : null}

                                                            </React.Fragment>



                                                        )
                                                    })}




                                                    <h6 class="dropdown-header">
                                                        Real Estate
                                                    </h6>

                                                    <a class="dropdown-item" onClick={() => this.props.history.push("rent")} href="#">
                                                        Property Rent Service
                                                    </a>

                                                    <a class="dropdown-item" onClick={() => this.props.history.push("sale")} href="#">
                                                        Property Sale Service
                                                    </a>

                                                </div>
                                                <div class="col-6 col-lg-6">

                                                    <h6 class="dropdown-header">
                                                        Insurance
                                                    </h6>

                                                    {this.state.Categorys.map((item, key) => {
                                                        return (

                                                            <React.Fragment>

                                                                {item.type == 'Insurance' ? (
                                                                    <a class="dropdown-item" href="" onClick={() => this.handleNext(item.id, item.name)} >
                                                                        {item.name}
                                                                    </a>

                                                                ) : null}

                                                            </React.Fragment>



                                                        )
                                                    })}



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="nav-item dropdown  border-left-lg">
                                        <a class="nav-link dropdown-toggle" id="navbarProduct" data-toggle="dropdown" href="#!" aria-haspopup="true" aria-expanded="false">
                                            Loan Services
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="navbarProduct">


                                            {this.state.Categorys.map((item, key) => {
                                                return (

                                                    <li>
                                                        <React.Fragment>

                                                            {item.type == 'Loan' ? (
                                                                <a class="dropdown-item" href="" onClick={() => this.handleNext(item.id, item.name, item.type)} >
                                                                    {item.name}
                                                                </a>
                                                            ) : null} </React.Fragment>

                                                    </li>

                                                )
                                            })}


                                        </ul>
                                    </li>

                                    <li class="nav-item dropdown  border-left-lg">
                                        <a class="nav-link dropdown-toggle" id="navbarProduct" data-toggle="dropdown" href="#!" aria-haspopup="true" aria-expanded="false">
                                            INSURANCE

                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="navbarProduct">

                                            {this.state.Categorys.map((item, key) => {
                                                return (

                                                    <li>
                                                        <React.Fragment>

                                                            {item.type == 'Insurance' ? (
                                                                <a class="dropdown-item" href="" onClick={() => this.handleNext(item.id, item.name, item.type)} >
                                                                    {item.name}
                                                                </a>
                                                            ) : null} </React.Fragment>

                                                    </li>

                                                )
                                            })}

                                        </ul>
                                    </li>


                                    <li class="nav-item dropdown  border-left-lg">
                                        <a class="nav-link dropdown-toggle" id="navbarProduct" data-toggle="dropdown" href="#!" aria-haspopup="true" aria-expanded="false">
                                            Real Estate
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="navbarProduct">

                                            <li><Link to={{
                                                pathname: '/rent',
                                                state: { data: "data" }
                                            }} class="dropdown-item">
                                                Rent Service
                                            </Link>
                                            </li>

                                            <li>
                                                <Link to="/sale" class="dropdown-item">
                                                    Sale Service
                                                </Link>
                                            </li>

                                        </ul>
                                    </li>

                                    <li class="nav-item dropdown  border-left-lg">
                                        <a class="nav-link dropdown-toggle" id="navbarProduct" data-toggle="dropdown" href="#!" aria-haspopup="true" aria-expanded="false">
                                            About Us
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="navbarProduct">

                                            <li><Link to={{
                                                pathname: '/about',
                                                state: { data: "data" }
                                            }} class="dropdown-item">
                                                About Compony
                                            </Link>
                                            </li>

                                            <li>
                                                <Link to="/partner" class="dropdown-item">
                                                    Our Partners
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to="/testimonial" class="dropdown-item">
                                                    Testimonial
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/team" class="dropdown-item">
                                                    Our Teams
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to="/gallery" class="dropdown-item">
                                                    Gallery
                                                </Link>
                                            </li>

                                        </ul>
                                    </li>




                                    <li class="nav-item  border-right-lg border-left-lg">
                                        <a class="nav-link " onClick={() => this.props.history.push("contact")} href="#">
                                            Contact us
                                        </a>
                                    </li>
                                    {/* 
                                    {cookie.get("isLogged") && (
                                        <li class="nav-item  border-right-lg border-left-lg">
                                            <a class="nav-link " href="javascript:void(0)" onClick={() => this.logOut()}>
                                                LogOut
                                            </a>
                                        </li>
                                    )} */}

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </React.Fragment>
        );
    }

    async logOut() {
        cookie.remove("isLogged", null);
        cookie.remove("UserID", null);
        cookie.remove("user_mobile_number", null);
        cookie.remove("user_name", null);
        window.location.reload();
    }

}



export default withRouter(Header);