
import React,{Component} from 'react';
import { Link, withRouter,Redirect } from 'react-router-dom';

import URL from '../URL'
import {Img} from 'react-image'

class AboutUS extends Component{
  constructor(props) {

    super(props);
   
      this.state = {
     
      }
      this.handleNext = this.handleNext.bind(this);
      this.handleNext = this.handleNext.bind(this);

  }

  handleBack() {

    this.props.history.goBack();

  }

  handleNext(Routation) {
   
    this.props.history.push(Routation);
  }


    render() { 



      
    return (

        <React.Fragment>

       
  <div class="page-header">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="page-breadcrumb">
            <ol class="breadcrumb">
              <li><a href=""> <Link to={{
                                                pathname: '/',
                                                state: { data: "data" }
                                            }} >
                                               Home
                                            </Link></a></li>
              <li class="active">About us</li>
            </ol>
          </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="bg-white pinside30">
            <div class="row align-items-center">
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <h1 class="page-title">About</h1>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
               
              </div>
            </div>
          </div>
    
        </div>
      </div>
    </div>
  </div>






  <div class=" ">
  
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="wrapper-content bg-white">
            <div class="about-section p-5">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <h2 class="mb30">Who We Are?</h2>
                  <p class="lead mb30">
                   First Loan Services is a highly rated lender with a long and rich history of working in the finance industry. With such a rich working experience comes the innate understanding of various financial challenges faced by our customers for their dream projects. In such scenarios, First Loan Services is here to cater to all your financial needs. Our offerings range from Business loans, Personal loans, Car loans, Loans against property, Home loan balance transfer facilities, specialized loans and a lot more. We are here to assist you achieve your dreams and clearing your financial hurdles with monetary assistance and financial advice.


                  </p>
                  <p>
                   You can simple acquire a loan within hours after the required documents are provided. With our dedicated specialists helping you all the way with the loan approval process, raising finances is as light as a breeze with First Loan Services. With a concrete vision and affordable financial solutions, we seek to empower your vision with our support and have been helping millions in the country to achieve their dreams.

Start building your dream with us and help us be a part of your success story today.
                  </p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div class="mb60">
                    <h2 class="mb30">Our Vision & Mission</h2>
                    <p class="lead mb30">
                     To be a recognized as a trusted ‘Financial Service’ agency serving customer needs and catering to them with the most cost-effective financial solutions with excellent financial expertise. We are committed in making your venture successful and providing a platform for self-reliance in the true sense by fine-tuning methodologies that are in line with practices adhering to national and international consulting practices.


                    </p>
                    <p>
                   With a mission to change the way people approach for finances, First Loan Services is dedicated to serve all your financial needs. Be it a home, business or a personal loan, First Loan Services provides the best deal for your venture at the lowest rates possible. As a professional financial service agency, we have a rich experience in consulting, have a versatile staff of bankers, lawyers, marketing professionals, and finance experts who understand your requirements and provide you with sound financial advice as well as strong financial backing.
                    </p>
                  </div>
                </div>
                <div class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                  <div class="text-center section-space80">
                    <h2>Why people choose us?</h2>
                    <p>
                    First Loan Services is known as an elite financial solution provider for our customers. With various loan offerings to businesses, housing projects and personal needs, we cater to many sections of the society including the Low Income Group (LIG), Economically Weaker Section (EWS) and Middle Income Group (MIG). By empowering our customers, we help them in their journey to realize their dreams.


                    </p>
                  </div>
                </div>
              </div>
            </div>
     

            <div class="section-space120 pinside60 bg-white">
              <div class="">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section-title text-center mb60">
                      <h1>Why apply with us</h1>
                    
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="text-center mb30">
                      <h3 class="mb-2">Dedicated Specialist for Specialized Loan Services</h3>
                      <p>
                      First Loan Services administers your relationship with a lender that fits your needs. We take special care in understanding the type of loan you require and proceed to connect you with like-minded people so that your dream project finds true success. With clear financial understanding and monetary advices, First Loan Services remains one of the most sought out agencies in the country when it comes to customized loan plans for specific project.


                      </p>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="text-center mb30">
                      <h3 class="mb-2">Success Stories Rating
</h3>
                      <p>
                     We are proud of our well-deserved recognition and rely on our deep knowledge and rich working experience. With hassle-free solutions and quick assessment, we provide loans and financial advisory to our esteemed customers and remain their go-to choice when it comes to our loan allocation for various ventures. First Loan Services is dedicated to providing efficient financial solutions to make your dreams come true and remain trusted among our peers as well as our prized customers.


                      </p>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="text-center mb30">
                      <h3 class="mb-2">Frequently Asked Questions
</h3>
                      <p>
                       What kind of financial advice do you give?<br/>
Where can I find market research reports?<br/>
Where should I incorporate my business?<br/>
What industries do you specialize in?<br/>


                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
       


            <div class="cta-section pinside60 bg-white section-space120">
              <div class=" ">
                <div class="row">
                  <div class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                    <div class="mb60 text-center section-title">
                 
                      <h1>We are here to help you</h1>
                      <p>
                        Our mission is to deliver reliable, latest news and
                        opinions.
                      </p>
                    </div>
                 
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="bg-white bg-boxshadow pinside40 outline text-center mb30">
                      <div class="mb40">
                        <i class="icon-calendar-3 icon-2x icon-default"></i>
                      </div>
                      <h2 class="capital-title">Apply For Loan</h2>
                      <p>
                        Looking to buy a car or home loan? then apply for loan
                        now.
                      </p>
                      <a href="#!" class="btn-link">
                      <Link to={{
                                                pathname: '/GetQuote',
                                                state: { data: "data" }
                                            }} class="dropdown-item">
                                               Get Appointment
                                            </Link>

                      </a>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="bg-white bg-boxshadow pinside40 outline text-center mb30">
                      <div class="mb40">
                        <i class="icon-phone-call icon-2x icon-default"></i>
                      </div>
                      <h2 class="capital-title">Call us at</h2>
                      <h1 class="text-big">789-7363-012</h1>
                      <p>lnfo@firstloanfinanceservice.com</p>
                      <a href="#!" class="btn-link">Contact us</a>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="bg-white bg-boxshadow pinside40 outline text-center mb30">
                      <div class="mb40">
                        <i class="icon-users icon-2x icon-default"></i>
                      </div>
                      <h2 class="capital-title">Talk to Advisor</h2>
                      <p>Need to loan advise? Talk to our Loan advisors.</p>
                      <a href="#!" class="btn-link">
                       <Link to={{
                                                pathname: '/contact',
                                                state: { data: "data" }
                                            }} class="dropdown-item">
                                               Meet The Advisor
                                            </Link>
                                            </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



        </React.Fragment>

        )};



  



  }

export default withRouter(AboutUS);
