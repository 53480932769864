import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Header from '../component/Header';
import Footer from '../component/Footer';
import RentComp from '../component/Rent';

class Rent extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {

        return (
            <>
                <Header />
                <RentComp />
                <Footer />
            </>
        )

    }
}

export default withRouter(Rent);