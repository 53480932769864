import React, { Component } from 'react';
import { Redirect, withRouter } from "react-router-dom";
import Header from '../component/Header';
import Footer from '../component/Footer';
import SignUp from '../component/SignUp';
import Cookies from 'universal-cookie';
const cookies = new Cookies()


class SignUpPage extends Component {
  constructor(props) {

    super(props);

    this.state = {

    }

  }

  componentDidMount() {

  }
  render() {

    return (
      <React.Fragment>


        <SignUp />


      </React.Fragment>
    );
  }

}

export default withRouter(SignUpPage);
