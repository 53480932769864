import React, { Component } from 'react';
import URL from '../URL'
import { withRouter ,Link} from 'react-router-dom';
import * as NumericInput from "react-numeric-input";
import Cookies from 'universal-cookie';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const cookies = new Cookies();


class ReviewCustomer extends Component {
    constructor(props) {

        super(props);

        this.state = {
            ReviewsList: [],
            isLoading: true,
            banners: []
        }


        this.handleNext = this.handleNext.bind(this);


    }




    async componentDidMount() {

        this.fetchReviewsList();
        this.FetchBanners();


    }

    FetchBanners = () => {

        // console.log('h=ittt ')
        fetch(URL + "/APP-API/Product/getSlider", {
            method: 'post',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({

                type: 'Website'

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                this.setState({ banners: responseJson.data });
            })
            .catch((error) => {
                // console.error(error);
            });

    }

    fetchReviewsList() {
        fetch(URL + "/APP-API/Product/GetReviewList", {
            method: 'post',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({})
        })
            .then((response) => response.json())
            .then((responseJson) => { this.setState({ isLoading: false, ReviewsList: responseJson.data, }); })
            .catch((error) => {
            });
    }



    render() {




        return (

            <React.Fragment>

            

                {this.state.isLoading ? null :
                    (
                        <React.Fragment>

       

                            <div class="section-space80">
                                <div class="container">
                                    <div class="row">
                                        <div class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                                            <div class="section-title mb60 text-center">
                                             
                        <h1>Loved by 1,000+ people around the UP!</h1>
                                                <p> Our Customers Reviews about us.</p>
                                            </div>
                                           
                </div>
                                    </div>
                                    <div class="row">



                                      {this.state.ReviewsList.slice(0, 3).map((item, key) => {
                        return (


                          <React.Fragment>

                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="customer-block">
                                                <div class="customer-quote-circle">
                                                    <i class="fa fa-quote-right "></i>
                                                </div>
                                                <div class="customer-img"><img src={URL + "/image/loan_icon/" + item.images} style={{ width: 70, height: 70 }} alt="Borrow - Loan Company Responsive Website Templates" class="img-circle" /></div>
                                                    <div class="customer-content">
                                                        <p class="customer-text">“{item.post}"</p>
                                                        <div class="customer-meta"><span class="customer-name">{item.name}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                       </React.Fragment>





                        )
                      })}


         



                                                </div>
                                            </div>

                                            	<div class="row">
                                    <div class="offset-xl-2 col-xl-8 offset-md-2 col-md-8 offset-md-2 col-md-8 col-sm-12 col-12 text-center my-3"> <a href="#!" class="btn btn-dark"><Link to={{ pathname: '/testimonial' }} > Check Out All Reviews </Link></a> </div>
            </div>

                                        </div>



                        </React.Fragment>
                    )}


            </React.Fragment>

        )
    };

    handleNext(loanId, loanName) {
        const Routation = "/Loan/" + loanId + "/" + loanName;
        this.props.history.push(Routation);

    }

}

export default withRouter(ReviewCustomer);