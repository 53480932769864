import React, { Component } from 'react';
import URL from '../URL'
import { withRouter } from 'react-router-dom';
import * as NumericInput from "react-numeric-input";
import Cookies from 'universal-cookie';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const cookies = new Cookies();


class Services extends Component {
  constructor(props) {

    super(props);

    this.state = {
      LoanList: [],
      isLoading: true,
      banners: []
    }


    this.handleNext = this.handleNext.bind(this);


  }




  async componentDidMount() {

    this.fetchLoanList();
    this.FetchBanners();


  }

  FetchBanners = () => {

    // console.log('h=ittt ')
    fetch(URL + "/APP-API/Product/getSlider", {
      method: 'post',
      header: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      },
      body: JSON.stringify({

        type: 'Website'

      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        this.setState({ banners: responseJson.data });
      })
      .catch((error) => {
        // console.error(error);
      });

  }

  fetchLoanList() {
    fetch(URL + "/APP-API/Product/GetLoanList", {
      method: 'post',
      header: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      },
      body: JSON.stringify({})
    })
      .then((response) => response.json())
      .then((responseJson) => { this.setState({ isLoading: false, LoanList: responseJson.data, }); })
      .catch((error) => {
      });
  }



  render() {




    return (

      <React.Fragment>

        {this.state.banners.length ? (
          <OwlCarousel className='owl-theme my-3 bg-light'
            items={1}
            loop
            margin={20}
            nav={true}
            autoplay={true}
            dots={false}
            navContainerClass='owl-nav custom-nav'
            navClass={["owl-prev", "owl-next ml-auto"]}
            navText={["<button style='padding: 11px 19px; background: rgb(0 0 0 / 42%); border-radius: 50%; border: none' class='button_my'><i class='fa fa-angle-left' style='color: #fff' aria-hidden='true'></i></button>",
              "<button style='padding: 11px 19px; background: rgb(0 0 0 / 42%); border-radius: 50%; border: none' class='button_my'><i class='fa fa-angle-right' style='color: #fff' aria-hidden='true'></i></button>"]}
          >
            {this.state.banners.map((item, i) => {
              return (
                <div class='item'>
                  <img className="image_resposive" style={{ margin: "auto", width: "100%", height: "363px", objectFit: "contain" }} src={URL + "/image/banner-image/" + item.image} />
                </div>
              )
            })}
          </OwlCarousel>
        ) : (null)}

        {this.state.isLoading ? null :
          (
            <React.Fragment>

              <div class="section-space80">
                <div class="container">
                  <div class="row">
                    <div class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                      <div class="mb60 text-center section-title">

                        <h1>Find Loan Products We Offers</h1>
                        <p>We will match you with a loan program that meet your financial need. In short term liquidity, by striving to make funds available to them <strong>within 24 hours of application.</strong></p>
                      </div>

                    </div>
                  </div>
                  <div class="" id="service">
                    <div class="row">


                      {this.state.LoanList.map((item, key) => {
                        return (


                          <React.Fragment>
                            {item.type == 'Loan' ? (
                              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-4 col-12">
                                <div onClick={() => this.handleNext(item.id, item.name,item.type)} class="service-block-v3">
                                  <div class="icon mb30">
                                    <a href="#!"> <img onClick={() => this.handleNext(item.id, item.name,item.type)} src={URL + "/image/loan_icon/" + item.image} alt="Borrow - Loan Company Website Template" class="icon-svg-2x" /></a>
                                  </div>
                                  <div class="service-content">
                                    <h2 class="service-title"><a href="#!" class="title">{item.name}</a></h2>
                                  </div>
                                  <div class="service-rate-block">
                                    <h3 class="product-rate">{item.intrest_rate}%</h3>
                                    <p class="rate-text">Rate of interest: </p>
                                  </div>
                                  <a href="" class="btn btn-secondary btn-block" onClick={() => this.handleNext(item.id, item.name,item.type)}>Apply now</a>
                                </div>
                              </div>
                            ) : null}
                          </React.Fragment>





                        )
                      })}




                    </div>
                  </div>
                </div>
              </div>



              <div class="section-space80">
                <div class="container">
                  <div class="row">
                    <div class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                      <div class="mb60 text-center section-title">

                        <h1>Let's find you the Best Insurance</h1>
                        <p>Customers trust us & have bought their insurance on <strong> First Loan Finance Services.</strong></p>
                      </div>

                    </div>
                  </div>
                  <div class="" id="service">
                    <div class="row">


                      {this.state.LoanList.map((item, key) => {
                        return (


                          <React.Fragment>
                            {item.type == 'Insurance' ? (
                              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">

                                <div onClick={() => this.handleNext(item.id, item.name,item.type)} class="service-img-box mb30 text-center outline">
                                  <div class="service-img">
                                    <a href="#" class="imghover"><img onClick={() => this.handleNext(item.id, item.name,item.type)} src={URL + "/image/loan_icon/" + item.image} alt="FLFS - Loan Company Website  " style={{ width: 200, height: 200 }} class="img-fluid" /></a>
                                  </div>
                                  <div class="service-content bg-white pinside20">
                                    <h2 class="mb-0"><a href="#" onClick={() => this.handleNext(item.id, item.name,item.type)} class="title">{item.name}</a></h2>
                                  </div>
                                </div>



                              </div>
                            ) : null}
                          </React.Fragment>





                        )
                      })}




                    </div>
                  </div>
                </div>
              </div>


            </React.Fragment>
          )}


      </React.Fragment>

    )
  };

  handleNext(loanId, loanName, loanType) {
    const Routation = "/procuct-details/" + loanId + "/" + loanName + "/" + loanType;
    this.props.history.push(Routation);

  }


}

export default withRouter(Services);