import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Header from '../component/Header';
import Footer from '../component/Footer';
import Rent from '../component/AddRent';
import Cookies from 'universal-cookie';

const cookie = new Cookies();

class AddR extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {


    }

    render() {

        if (cookie.get("isLogged")) {
            return (
                <>

                    <Header />

                    <Rent />

                    <Footer />

                </>

            )
        } else {
            return (
                <>
                    {this.props.history.push("/Login")}
                </>
            )
        }

    }
}

export default withRouter(AddR);
