

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';


import $ from 'jquery';
import Cookies from 'universal-cookie';
const cookies = new Cookies()
class Footer extends Component {
	constructor(props) {

		super(props);

		this.state = {
			Categorys: []
		}

		this.handleNext = this.handleNext.bind(this);
		//   this.handleBack = this.handleBack.bind(this); 


	}


	async componentDidMount() {

		await this.fetchCategorys()


	}

	async fetchCategorys() {
		await fetch(URL + "/APP-API/Product/GetLoanList", {
			method: 'post',
			header: {
				'Accept': 'application/json',
				'Content-type': 'application/json'
			},
			body: JSON.stringify({


			})

		})
			.then((response) => response.json())
			.then((responseJson) => {


				//   console.log('Categorys',responseJson)

				this.setState({ Categorys: responseJson.data, inMemoryrestorentData: responseJson.data });





			})
			.catch((error) => {
				//  console.error(error);

			});


	}

	handleNext(loanId, loanName, loanType) {
		const Routation = "/procuct-details/" + loanId + "/" + loanName + "/" + loanType;
		this.props.history.push(Routation);

	}

	render() {

		return (

			<React.Fragment>
				<div class="footer section-space100">

					<div class="container ">

						<hr class="dark-line " />
						<div class="row ">
							<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
								<div class="widget-text mt40 ">

									<p>To be a recognized as a trusted ‘Financial Service’ agency serving customer needs and catering to them with the most cost-effective financial solutions with excellent financial expertise. We are committed in making your venture successful and providing a platform for self-reliance in the true sense by fine-tuning methodologies that are in line with practices adhering to national and international consulting practices.</p>
									<div class="row ">
										<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 ">
											<p class="address-text "><span><i class="icon-placeholder-3 icon-1x "></i> </span> Ramnagar Colony Mohaddipur Gorakhpur</p>
										</div>
										<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 ">
											<p class="call-text "><span><i class="icon-phone-call icon-1x "></i></span>790-5931-492</p>
										</div>
									</div>
								</div>

							</div>
							<div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 ">
								<div class="widget-footer mt40 ">

									<ul class="listnone ">
										<li><a href="# " onClick={() => this.props.history.push("/")}>Home</a></li>

										<li><a href="# " onClick={() => this.props.history.push("about")}>About Us</a></li>
										<li><a href="# " onClick={() => this.props.history.push("partner")}>Our Partners</a></li>
										<li><a href="# " onClick={() => this.props.history.push("testimonial")}>Testimonial</a></li>
										<li><a href="# " onClick={() => this.props.history.push("team")}>Team</a></li>
										<li><a href="# " onClick={() => this.props.history.push("gallery")}>Gallery</a></li>
										<li><a href="# " onClick={() => this.props.history.push("contact")}>Contact</a></li>
									</ul>
								</div>

							</div>
							<div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 ">
								<div class="widget-footer mt40 ">

									<ul class="listnone ">

										{this.state.Categorys.map((item, key) => {
											return (

												<React.Fragment>

													{item.type == 'Loan' ? (
														<li>
															<a class="dropdown-item" href="#" onClick={() => this.handleNext(item.id, item.name, item.type)} >
																{item.name}
															</a>
														</li>


													) : null}

												</React.Fragment>



											)
										})}


									</ul>
								</div>

							</div>
							<div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 ">
								<div class="widget-social mt40 ">

									<ul class="listnone ">
										<li><a href="#!"><i class="fab fa-facebook-f "></i>Facebook</a></li>
										<li><a href="#!"><i class="fab fa-google-plus "></i>Google Plus</a></li>
										<li><a href="#!"><i class="fab fa-twitter "></i>Twitter</a></li>
										<li><a href="#!"><i class="fab fa-linkedin "></i>Linked In</a></li>
									</ul>
								</div>

							</div>
						</div>
					</div>
				</div>

				<div class="tiny-footer">

					<div class="container">
						<div class="row">
							<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
								<p>Design & Developed By|  <a target="_blank" href="https://skyablyitsolution.com/"> SKYABLY IT SOLUTION  </a> </p>
							</div>
							<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text-right">
								<p>Terms of use | <a target="_blank" href="https://firstloanfinancialservices.com/privacy.php"> Privacy Policy </a> </p>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}


}



export default withRouter(Footer);
