
import React,{Component} from 'react';
import URL from '../URL'
import { withRouter ,Link} from 'react-router-dom';
import * as NumericInput from "react-numeric-input";
import Cookies from 'universal-cookie';
const cookies = new Cookies();


class TeamMember extends Component{
    constructor(props) {

    super(props);
   
      this.state = {
        TeamList:[]
      }
    



    }




    async  componentDidMount() {

      await  this.fetchTeamList()
   
       
     }
   
     fetchTeamList() {
       fetch(URL + "/APP-API/Product/GetTeamList", {
         method: 'post',
         header: {
           'Accept': 'application/json',
           'Content-type': 'application/json'
         },
         body: JSON.stringify({
   
   
         })
   
       })
         .then((response) => response.json())
         .then((responseJson) => {
   
   
            console.log('TeamList',responseJson)
   
           this.setState({ TeamList: responseJson.data, inMemoryrestorentData: responseJson.data });
   
   
   
   
   
         })
         .catch((error) => {
           //  console.error(error);
   
         });
   
   
     }
   
   
    render() { 



    
    return (
       
    <React.Fragment>

            

                {this.state.isLoading ? null :
                    (
                        <React.Fragment>

       

		 <div class="bg-default section-space80">
        <div class="container">
            <div class="row">
                <div class="offset-xl-2 col-xl-8 offset-md-2 col-md-8 offset-md-2 col-md-8 col-sm-12 col-12">
                    <div class="mb60 text-center section-title">
                     
                        <h1 class="title-white">Our Team Members</h1>
                        <p class="text-white"> Meet the driving force behind our success..</p>
                    </div>
                   
                </div>
            </div>
            <div class="row">

                                  {this.state.TeamList.slice(0, 3).map((item, key) => {
                        return (


                          <React.Fragment>

                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                                             <div class="testimonial-block mb30">
                     
                        <div class="testimonial-autor-box">
                            <div class="testimonial-img pull-left"> <img src={URL + "/image/loan_icon/" + item.images} style={{ width: 150, height: 150 }}  alt="Borrow - Loan Company Website Template"/> </div>
                            <div class="testimonial-autor pull-left">
                                <h4 class="testimonial-name">{item.name}</h4>
                                <span class="testimonial-meta text-white">{item.post}</span>
                            </div>
                        </div>
                    </div>
                                            </div>


                                         
                                       </React.Fragment>





                        )
                      })}
      

            
           
            </div>

			
        </div>

	

    </div>

		  <div class="row">
                <div class="offset-xl-2 col-xl-8 offset-md-2 col-md-8 offset-md-2 col-md-8 col-sm-12 col-12 text-center mt-3"> <a href="#!" class="btn btn-dark"><Link to={{ pathname: '/team' }} > See all Members </Link></a> </div>
            </div>

	               </React.Fragment>
                    )}


            </React.Fragment>


        )};

  }

export default withRouter(TeamMember);
