import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import URL from '../URL';
import { RiLoader4Line } from 'react-icons/ri';

const cookies = new Cookies()


class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isloading: false
    }

  }

  handleNext(Routation) {
    this.props.history.push(Routation);
  }

  onChange = (e) => {

    if (e.target.id === 'email') {
      this.setState({ email: e.target.value });
    } else if (e.target.id === 'password') {
      this.setState({ password: e.target.value });
    }

  }


  componentDidMount() {



  }


  render() {

    return (
      <>
        <div className="baap">
          <div class="main">
            <p class="sign" align="center">Sign in</p>
            <form class="form1">
              <input type="email" onChange={this.onChange} align="center" class="un" id="email" placeholder="Email" required="" />
              <input type="password" onChange={this.onChange} class="pass" align="center" id="password" placeholder="Enter your password" required="" />
              <a class="submit" onClick={() => this.LoginFunction()} align="center">{this.state.isloading ? (<RiLoader4Line className="rotate" />) : "Sign in"}</a>
              <p class="forgot" style={{ fontSize: 12 }} align="center"><Link to="">Forgot Password?</Link></p>
              <p class="signUp" style={{ color: "rgb(189 189 189)", fontWeight: "700", marginTop: 8 }} align="center">Create an account by <Link to="/SignUp" style={{ fontWeight: "500" }}>Sign Up.</Link></p>
            </form>
          </div>
        </div>
      </>

    );

  }

  async LoginFunction() {

    this.setState({ isloading: true, errorr: '' })
    var mobileno = /^\d{10}$/;
    if (this.state.email == "") {
      this.setState({ isloading: false, })
      var msg = 'Please fill mobile number'
      toast.error(msg)
    } else if (this.state.password == '') {
      this.setState({ isloading: false, })
      var msg = 'Password Requird'
      toast.error(msg)
    } else {
      this.setState({ isloading: true });
      console.log("goning good", this.state);
      fetch(URL + '/APP-API/Partner/Login', {
        method: 'POST',
        header: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          email: this.state.email,
          password: this.state.password,
        })
      }).then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.Login == "done") {
            var msg = 'You have Logged successfully'
            toast.success(msg)
            cookies.set("isLogged", true, { maxAge: 999999999999 });
            cookies.set("UserID", responseJson.data[0].id, { maxAge: 999999999999 });
            cookies.set("user_mobile_number", responseJson.data[0].mobile, { maxAge: 999999999999 });
            cookies.set("user_name", responseJson.data[0].name, { maxAge: 999999999999 });
            cookies.set("roal_type", responseJson.data[0].roal_type, { maxAge: 999999999999 });
            this.handleNext("/")
          } else {
            var msg = 'Phone or Password not Matched'
            toast.error(msg)
          }

        })
        .catch((error) => {
          console.error("coustome error", error);
        });
    }

  }



}

export default withRouter(Login);