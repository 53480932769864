import React, { Component } from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import Header from '../component/Header';
import Footer from '../component/Footer';
import Login from '../component/Login';
import Cookies from 'universal-cookie';
const cookies = new Cookies()


class LoginPage extends Component {
  constructor(props) {

    super(props);

    this.state = {

    }

  }

  componentDidMount() {

  }

  render() {

    return (
      <>
        <Login />
      </>
    );
  }

}

export default withRouter(LoginPage);
