import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router';
import Cookies from 'universal-cookie';
import { FcAbout } from 'react-icons/fc';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import formatAmount from 'indian-currency-formatter';
import Loader from "react-loader-spinner";


const cookie = new Cookies();

class Rent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            ListingData: [],
            UserID: null,
            show_listing: 6
        }

    }

    componentDidMount = async () => {
        // this.fetchAllResult();
        this.fetchNew();
        this.setState({ UserID: await cookie.get("UserID") })
    }

    fetchNew = () => {
        fetch(URL + "/APP-API/Partner/GetRealEsateData", {
            method: 'post',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({

                type: "Sale"

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                console.log(responseJson);
                this.setState({ isLoading: false, ListingData: responseJson.data });
            })
            .catch((error) => {
                // console.error(error);
            });
    }

    handleNext = (props) => {
        this.props.history.push(props);
    }

    addLeadProperty(id, p_goal) {

        fetch(URL + '/APP-API/Partner/addLeadProperty', {

            method: 'post',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({

                property_id: id,
                p_goal: p_goal,
                UserID: this.state.UserID,

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status == 'done') {
                    toast.success("We will contact you soon!");
                }
                else {
                    toast.error("Network are bussy! Please try after sometime");
                }

            })
            .catch((error) => {
                //  console.error(error);

            });


    }

    render() {
        const nth = function (d) {
            if (d > 3 && d < 21) return 'th';
            switch (d % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
            }
        };
        const month = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        if (this.state.isLoading) {
            return (
                <>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "500px", background: "#fff" }}>
                        <Loader type="MutatingDots" color="#f51f8a" secondaryColor="#2573cb" height={150} width={150} />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className="container">

                        <div className="">
                            <div className="row align-items-center bg-white mt-4 p-4">
                                <div className="col-12">
                                    <h1 className="page-title text-center">Property Sale Service</h1>
                                </div>
                            </div>

                            <div className="row">
                                <div class="col-xl-12 col-12">
                                    {this.state.ListingData.slice(0, this.state.show_listing).map((items, i) => {
                                        return (
                                            <div class="row my-4 bg-white list-rent">
                                                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <img style={{ boxShadow: "0 1px 6px 0 #888888", height: 160, objectFit: "cover", margin: "25px auto", display: "block", borderRadius: 5 }} src={URL + "/APP-API/Assets/documents/" + (items.cover_image == null ? "no-image-available-icon.jpg" : items.cover_image)} />
                                                </div>
                                                <div class="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-12 p-4">
                                                    <div className="row">
                                                        <div style={{ cursor: "pointer" }} onClick={() => this.props.history.push({ pathname: '/details', state: { id: items.id } })} className="col-8"><h4 style={{ fontWeight: "400", textOverflow: "ellipsis", width: "85%", height: "1.5em", overflow: "hidden", whiteSpace: "nowrap" }}>{items.heading}</h4></div>
                                                        <div className="col-4"><div className="d-flex justify-content-end align-items-center"><FcAbout />  <h4 style={{ fontWeight: "700", marginBottom: "0", fontSize: 12, textAlign: "end" }}>&nbsp;{items.p_type}</h4></div></div>
                                                    </div>
                                                    <h5>{items.pin_code}, {items.city}</h5>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <h3 style={{ fontWeight: "700", marginBottom: 0 }}>&#x20B9; <span className="ml-1"> {formatAmount(items.customer_price)}</span></h3>
                                                            <p style={{ fontSize: 10 }}>Fix Price</p>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="d-flex" style={{ alignItems: "baseline" }}>
                                                                <h3 style={{ fontWeight: "700", marginBottom: 0 }}>{items.area == null ? "No Data" : items.area} </h3><span style={{ fontSize: 10, color: "#8a8a8a" }}>sq/ft</span>
                                                            </div>
                                                            <p style={{ fontSize: 10 }}>({(items.area * 0.09290304).toFixed(2)} sq/m) super build</p>
                                                        </div>
                                                        <div className="col-3">
                                                            <h3 style={{ fontWeight: "700", marginBottom: 0 }}>{items.BHK}</h3>
                                                        </div>
                                                        <div className="col-3"></div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p style={{ textOverflow: "ellipsis", width: "85%", height: "1.5em", overflow: "hidden", whiteSpace: "nowrap", marginTop: "6px" }}>{items.details == null ? "No Discription..! Please contact us for more information." : items.details}</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="row mt-3">
                                                    <div className="d-flex col-12">
                                                        <div style={{ background: "#EBECF0", width: "fit-content", borderRadius: 3, marginRight: 5 }}>
                                                            <p style={{ fontSize: 10, color: "#42526E", padding: "2px 8px", textTransform: "uppercase" }}>bedroom : {items.rooms}</p>
                                                        </div>
                                                        <div style={{ background: "#EBECF0", width: "fit-content", borderRadius: 3, marginRight: 5 }}>
                                                            <p style={{ fontSize: 10, color: "#42526E", padding: "2px 8px", textTransform: "uppercase" }}>Bathroom : {items.bathrooms} </p>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                </div>
                                                <div class="col-xl-12 col-12 py-3 px-4" style={{ borderTop: "1px solid #EBECF0" }}>
                                                    <div className="row">
                                                        <div className="col-6 d-flex align-items-center">
                                                            <p style={{ fontSize: 10, margin: 0 }}>Posted on {items.date.split("/")[0]}{nth(items.date.split("/")[0])} {month[(items.date.split("/")[1]).toString().replace(/^0+/, '')]}, {items.date.split("/")[2]} by Verifyed Customer</p>
                                                        </div>
                                                        <div className="col-6 d-flex align-items-center justify-content-end">
                                                            <a href="JavaScript:void(0)" onClick={() => this.props.history.push({ pathname: '/details', state: { id: items.id } })} className="buttons__primaryRegular"><span>Show More</span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="show_more m-4 d-flex justify-content-center">
                                {this.state.ListingData.length <= this.state.show_listing ? (
                                    <a href="javascript:void(0)" className="buttons__primaryRegular" style={{ background: "#bdbdbd", pointerEvents: "none" }}>That's All Mate</a>
                                ) : (
                                    <a href="javascript:void(0)" className="buttons__primaryRegular" onClick={() => this.setState({ show_listing: this.state.show_listing + 6 })} style={{ background: "#15549a" }}>Load More</a>
                                )}
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </>
            )
        }
    }
}

export default withRouter(Rent);