import React, { Component } from 'react';
import { Switch, Route, Router, BrowserRouter, withRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import SplshImage from './component/SplshImage';
import Theme from './component/Theme';
import HomePage from './Page/HomePage';
import LoanCalculaterPage from './Page/LoanCalculaterPage';
import LoanPage from './Page/LoanPage';
import AboutUsPage from './Page/AboutUsPage';
import ServicesPage from './Page/ServicesPage';
import GetQuotePage from './Page/GetQuotePage';
import DetailPage from './Page/DetailPage';
import NotFoundPage from './Page/NotFoundPage';
import RentPage from './Page/RentPage';
import SellPage from './Page/SellPage';
import Cookies from 'universal-cookie';
import AddRent from './Page/AddRentPage';
import LoginPage from './Page/LoginPage';
import SignUpPage from './Page/SignUpPage';
import EligibilityCalculatorPage from './Page/EligibilityCalculatorPage';
import LoanCalculator from './Page/LoanCalculator';
import TeamPage from './Page/TeamPage';
import GalleryPage from './Page/GalleryPage';
import PartnerPage from './Page/PartnerPage';
import TestmonialPage from './Page/TestmonialPage';
import ContactUsPage from './Page/ContactUsPage';

const cookies = new Cookies()



class App extends Component {
  constructor(props) {

    super(props);

    this.state = {

    }


  }


  async componentDidMount() {

    // cookies.set("visited", true, { maxAge: 999999999999 });
    window.addEventListener('online', () => alert('came online'));
    window.addEventListener('offline', () => alert('came offline'));

  }

  render() {
    return (

      <BrowserRouter>

        <LastLocationProvider>

          <SplshImage />




          <Switch>

            <Route exact path="/" >
              <HomePage />
            </Route>

            <Route exact path="/loan-calculater" >
              <LoanCalculaterPage />
            </Route>

            <Route exact path="/about" >
              <AboutUsPage />
            </Route>
            <Route exact path="/contact" >
              <ContactUsPage />
            </Route>



            <Route exact path="/rent" >
              <RentPage />
            </Route>

            <Route exact path="/team" >
              <TeamPage />
            </Route>

            <Route exact path="/gallery" >
              <GalleryPage />
            </Route>

            <Route exact path="/partner" >
              <PartnerPage />
            </Route>

            <Route exact path="/testimonial" >
              <TestmonialPage />
            </Route>



            <Route exact path="/sale" >
              <SellPage />
            </Route>

            <Route exact path="/eligibilyCalc" >
              <EligibilityCalculatorPage />
            </Route>

            <Route exact path="/LoanCalculator" >
              <LoanCalculator />
            </Route>

            {/* <Route exact path="/AddRent" >
              <AddRent />
            </Route> */}

            <Route exact path="/details" >
              <DetailPage />
            </Route>

            {/* <Route exact path="/Login" >
              <LoginPage />
            </Route>

            <Route exact path="/SignUp" >
              <SignUpPage />
            </Route> */}

            <Route exact path="/services/:servicesName" >
              <ServicesPage />
            </Route>

            <Route exact path="/GetQuote" >
              <GetQuotePage />
            </Route>

            <Route exact path="/procuct-details/:loanId?/:loanName?/:loanType?" >
              <LoanPage />
            </Route>

            <Route path='*' exact={true}>
              <NotFoundPage />
            </Route>

          </Switch>






          <Theme />
        </LastLocationProvider>

      </BrowserRouter>

    );
  }

}

export default App;
