import React, { Component } from 'react';
import { Redirect, withRouter, Link } from "react-router-dom";
import Header from '../component/Header';
import Footer from '../component/Footer';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';
import { Helmet } from "react-helmet";

const cookies = new Cookies()

class ContactUsPage extends Component {
    constructor(props) {

        super(props);

        this.state = {
            isClickedAdd: false,
            UserPhone: '',
            UserFullName: '',
            email: '',
        
            messege: '',
            UserID: null,
            Categorys: []
        }
        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);

        this.onChange = this.onChange.bind(this);

    }

    handleBack() {

        this.props.history.goBack();

    }

    handleNext(Routation) {
        this.props.history.push(Routation);
    }

    onChange(e) {

        if (e.target.id === 'name') {
            this.setState({ UserFullName: e.target.value });
        } else if (e.target.id === 'mobile') {
            this.setState({ UserPhone: e.target.value });
        } else if (e.target.id === 'email') {
            this.setState({ email: e.target.value });
        } else if (e.target.id === 'messege') {
            this.setState({ messege: e.target.value });
        } 

    }


    async componentDidMount() {

        var UserID = await cookies.get('UserID');
        this.setState({ UserID })
        


    }


    render() {




        return (
            <React.Fragment>

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Team - First Loan Finance Service</title>
                    <link rel="canonical" href="http://mysite.com/example" />
                    <link rel="icon" type="image/png" href="/assets/img/favicon.png" />

                    <meta name="description" content="First Loan Financial Services is a best services provider of all types of loan, Home Loan in Gorakhpur, Personal Loan in Gorakhpur and Property Loan in Gorakhpur" />
                    <meta name="keywords" content="First Loan Financial Services is a best services provider of all types of loan, Home Loan in Gorakhpur, Personal Loan in Gorakhpur and Property Loan in Gorakhpur" />
                    <meta name="author" content="First Loan Finance Service" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />


                </Helmet>



                <Header />




                <div class="page-header">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="page-breadcrumb">
                                    <ol class="breadcrumb">
                                        <li><a href="#"><Link to={{
                                            pathname: '/',
                                            state: { data: "data" }
                                        }} >
                                            Home
                                            </Link></a></li>
                                        <li class="active">Contact Us</li>
                                    </ol>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="bg-white pinside30">
                                    <div class="row align-items-center">
                                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                            <h1 class="page-title">Contact Us</h1>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>



                <div class="">
        <div class="container">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="wrapper-content bg-white p-3 p-lg-5">
                                    <div class="contact-form mb60">
                                        <div class=" ">
                                            <div class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                                                <div class="mb60  section-title text-center  ">
                                                  
                                        <h1>Get In Touch</h1>
                                                    <p>Reach out to us &amp; we will respond as soon as we can.</p>
                                                </div>
                                            </div>
                                          
                                                <div class=" ">
                                                   
                                        <div class="row">
                                                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                            <div class="form-group">
                                                                <label class="sr-only control-label" for="name">name<span class=" "> </span></label>
                                                                <input id="name" onChange={this.onChange} id="name" type="text" placeholder="Name" class="form-control input-md" required/>
                                                </div>
                                                            </div>
                                                          
                                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label class="sr-only control-label" for="email">Email<span class=" "> </span></label>
                                                                <input onChange={this.onChange} id="email" name="email" type="email" placeholder="Email" class="form-control input-md" required/>
                                                </div>
                                                                </div>
                                                           
                                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                                    <div class="form-group">
                                                                        <label class="sr-only control-label" for="phone">Phone<span class=" "> </span></label>
                                                                <input onChange={this.onChange} id="mobile" name="phone" type="text" placeholder="Phone" class="form-control input-md" required/>
                                                </div>
                                                                    </div>
                                                                 
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                        <div class="form-group">
                                                                            <label class="control-label" for="message"> </label>
                                                                <textarea class="form-control" onChange={this.onChange} id="messege" name="messege" rows="7" name="message" placeholder="Message"></textarea>
                                                                        </div>
                                                                    </div>
                                                                  
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <button onClick={() => this.SaveAddress()} type="submit" class="btn btn-secondary">Submit</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                              
                                                    </div>
                                                </div>
                                             
                        <div class="contact-us mb60">
                                                    <div class="row">
                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <div class="mb60  section-title">
                                                             
                                        <h1>We are here to help you </h1>
                                                                <p class="lead">Various versions have evolved over the years sometimes by accident sometimes on purpose injected humour and the like.</p>
                                                            </div>
                                                           
                                </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                            <div class="bg-boxshadow pinside60 outline text-center mb30">
                                                                <div class="mb40"><i class="icon-briefcase icon-2x icon-default"></i></div>
                                                                <h2 class="capital-title">Branch Office</h2>
                                                    <p>Ramnagar Colony
                                            <br /> Mohaddipur Gorakhpur</p>
                                    </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                                <div class="bg-boxshadow pinside60 outline text-center mb30">
                                                                    <div class="mb40"><i class="icon-phone-call icon-2x icon-default"></i></div>
                                                                    <h2 class="capital-title">Call us at </h2>
                                                    <h1 class="text-big">790-5931-492 </h1>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                                <div class="bg-boxshadow pinside60 outline text-center mb30">
                                                                    <div class="mb40"> <i class="icon-letter icon-2x icon-default"></i></div>
                                                                    <h2 class="capital-title">Email Address</h2>
                                                                    <p>lnfo@firstloanfinancialservice.com</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                   
                </div>
                                        </div>
                                    </div>
                                </div>


                <ToastContainer />

                <Footer />

            </React.Fragment>
        );
    }


    async SaveAddress() {



        this.setState({ isClickedAdd: true, })



        const { UserFullName, UserPhone, email, messege } = this.state;

        var phoneno = /^\d{10}$/;

        if (UserFullName === "") {

            this.setState({ isClickedAdd: false, })
            var msg = '**Name Requird';
            toast.error(msg)
        }

        else if (UserPhone === '') {
            this.setState({ isClickedAdd: false, })

            var msg = '** Enter Mobile Number';
            toast.error(msg)

        }


     


        else if (messege === '') {
            this.setState({ isClickedAdd: false, })

            var msg = '** Enter Message';
            toast.error(msg)

        }


     


        else {



            this.setState({ isClickedAdd: true })
            fetch(URL + "/APP-API/App/insertContact", {
                method: 'post',
                header: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({


                    UserFullName: UserFullName,
                    UserPhone: UserPhone,
                    email: email,
                    messege: messege,

                })


            }).then((response) => response.json())
                .then((responseJson) => {

                    this.setState({ isClickedAdd: false })


                    console.log(responseJson)

                    if (responseJson.status == 'done') {

                        var msg = 'We will contact you soon.'
                        toast.success(msg)



                    }



                })
                .catch((error) => {
                    //  console.error(error);

                });

        }




    }

}

export default withRouter(ContactUsPage);
