import React, { Component } from 'react';
import { Redirect, withRouter, Link } from "react-router-dom";
import Header from '../component/Header';
import Footer from '../component/Footer';


import { Helmet } from "react-helmet";



class PartnerPage extends Component {
    constructor(props) {

        super(props);

        this.state = {
            PartnerList: []
        }




    }




    async componentDidMount() {

        await this.fetchPartnerList()


    }

    fetchPartnerList() {
        fetch(URL + "/APP-API/Product/GetPartnersList", {
            method: 'post',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({


            })

        })
            .then((response) => response.json())
            .then((responseJson) => {


                console.log('PartnerList', responseJson)

                this.setState({ PartnerList: responseJson.data, inMemoryrestorentData: responseJson.data });





            })
            .catch((error) => {
                //  console.error(error);

            });


    }






    render() {




        return (
            <React.Fragment>

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Partner - First Loan Finance Service</title>
                    <link rel="canonical" href="http://mysite.com/example" />
                    <link rel="icon" type="image/png" href="/assets/img/favicon.png" />

                    <meta name="description" content="First Loan Financial Services is a best services provider of all types of loan, Home Loan in Gorakhpur, Personal Loan in Gorakhpur and Property Loan in Gorakhpur" />
                    <meta name="keywords" content="First Loan Financial Services is a best services provider of all types of loan, Home Loan in Gorakhpur, Personal Loan in Gorakhpur and Property Loan in Gorakhpur" />
                    <meta name="author" content="First Loan Finance Service" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />


                </Helmet>



                <Header />




                <div class="page-header">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="page-breadcrumb">
                                    <ol class="breadcrumb">
                                        <li><a href="#"><Link to={{
                                            pathname: '/',
                                            state: { data: "data" }
                                        }} >
                                            Home
                                            </Link></a></li>
                                        <li class="active">Meet Our Partner</li>
                                    </ol>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="bg-white pinside30">
                                    <div class="row align-items-center">
                                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                            <h1 class="page-title">Partner</h1>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>



                <div class=" ">

                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="wrapper-content bg-white p-3 p-lg-5">
                                    <div class="row">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="mb60">
                                                <p class="lead">We love our Partner. And apparently, they love us back. Watch their stories about how they helped them get ahead. We look forward to working with you for all of your lending needs.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        {this.state.PartnerList.map((item, key) => {
                                            return (


                                                <React.Fragment>

                                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4">
                                                        <div class="Partner-block mb-5">
                                                            <div class="Partner-img mb-3"> <img src={URL + "/image/loan_icon/" + item.images} style={{ width: 150, height: 100 }} class="img-fluid" /> </div>
                                                            <div class="Partner-content text-center">
                                                                <h3 class="mb-0">{item.name}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>





                                            )
                                        })}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <Footer />

            </React.Fragment>
        );
    }

}

export default withRouter(PartnerPage);
